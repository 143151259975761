/*Border styling*/
.rounded {
  border-radius: 10px;
  &-lg {
    border-radius: 18px;
  }
  &-left {
    &-sm {
      border-radius: 6px 0 0 6px;
    }
    border-radius: 10px 0 0 10px;
  }
  &-right {
    &-sm {
      border-radius: 0 6px 6px 0;
    }
    border-radius: 0 10px 10px 0;
  }
  &-top {
    &-right {
      border-top-right-radius: 10px;
    }
    &-left {
      border-top-left-radius: 10px;
    }
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  &-bottom {
    &-right {
      border-bottom-right-radius: 10px;
    }
    &-left {
      border-bottom-left-radius: 10px;
    }
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
.border {
  border: 1px solid var(--brand-success);
}
.border-top {
  border-top: 1px solid var(--brand-success);
}
.border-bottom {
  border-bottom: 1px solid var(--brand-success);
}
.border-left {
  border-left: 1px solid var(--brand-success);
}
.border-right {
  border-right: 1px solid var(--brand-success);
}
.border-thick {
  border-width: 3px !important;
}
.border-secondary {
  border-color: var(--brand-secondary) !important;
}
.border-left-secondary {
  border-left-color: var(--brand-secondary) !important;
}
.border-top-secondary {
  border-top-color: var(--brand-secondary) !important;
}
.border-bottom-secondary {
  border-bottom-color: var(--brand-secondary) !important;
}
.border-right-secondary {
  border-right-color: var(--brand-secondary) !important;
}
.border-dark {
  border-color: var(--brand-dark) !important;
}
.border-left-dark {
  border-left-color: var(--brand-dark) !important;
}
.border-top-dark {
  border-top-color: var(--brand-dark) !important;
}
.border-bottom-dark {
  border-bottom-color: var(--brand-dark) !important;
}
.border-right-dark {
  border-right-color: var(--brand-dark) !important;
}
.border-darkgray {
  border-color: rgba(0, 0, 0, 0.25) !important;
}
