@import '../../theme-variable.scss';

.restriction-page-wrapper {
  height: 100vh;
  width: 100%;
  overflow: overlay;
  background: url(../../assets/restriction-purple-bg.svg) 50% 0% / auto
      no-repeat,
    url(../../assets/restriction-bg.svg) 50% 0% / cover,
    linear-gradient(180deg, #000d20 0%, #000810 100%);
}
.restriction-content-wrapper {
  height: 100%;
  padding-top: 60px;
  display: flex;
  justify-content: center;
}
.restriction-content {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  max-width: 400px;
}
.restriction-body {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  img {
    width: 60px;
    height: 60px;
  }
}
.restriction-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h3 {
    color: var(--neutral-100, #eeeef0);
    text-align: center;
    font-family: Nunito Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.27px;
  }
  h4 {
    color: #afc0d4;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Nunito Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 32px;
  }
  span {
    color: #007aeb;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 1.5em;
  }
  p {
    color: #afc0d4;
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
