@import '../../theme-variable.scss';
.notification {
  background-color: #2c2c2c !important;
  border-radius: 10px !important;

  .ant-notification-notice-message {
    font-weight: bold;
    line-height: 29px;
    color: #fff !important;
  }

  .ant-notification-notice-description {
    color: #fff !important;
    font-weight: normal;
    font-size: 13px !important;
    line-height: 175.47%;
  }

  .ant-notification-notice-close {
    color: #fff !important;
  }

  .notification-text-white {
    color: #fff !important;
  }
}
