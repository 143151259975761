@import '../../theme-variable.scss';

.glow-constant {
  border: none;
  outline: none;
  position: relative;
  z-index: 0;
  border-radius: 50px;
}

.glow-constant:before {
  content: '';
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: 0px;
  left: 0px;
  background-size: 350%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 0px);
  height: calc(100% + -4px);
  animation: glowing 20s linear infinite;
  opacity: 1;
  border-radius: 50px;
}
.btn-premium {
  color: #007aff !important;
  background: #212121 !important;

  // background: none !important;
  border: 1px solid #007aff !important;
  font-weight: 500;
  border-radius: 10px;
  &.btn-lg {
    height: 40px !important;
    font-size: 1.6em;
    min-width: 60%;
  }
  &.btn-md {
    height: 45px !important;
    font-size: 1.3em;
  }
  &.btn-sm {
    height: 35px !important;
    font-size: 1.2em;
  }
  &.rounded {
    border-radius: 18px;
  }
  &.rounded-sm {
    border-radius: 10px;
  }
  &:hover {
    filter: brightness(0.8);
    background: #212121 !important;
  }
  &:active {
    filter: brightness(0.6);
  }
  &.text-black {
    color: #000 !important;
  }
}
.btn-premium-light {
  color: #007aff !important;
  background: #ffffff !important;

  border: 1px solid #007aff !important;
  // font-weight: 500;
  border-radius: 10px;
  &.btn-lg {
    height: 40px !important;
    font-size: 1.6em;
    min-width: 60%;
  }
  &.btn-md {
    height: 45px !important;
    font-size: 1.3em;
  }
  &.btn-sm {
    height: 35px !important;
    font-size: 1.2em;
  }
  &.rounded {
    border-radius: 18px;
  }
  &.rounded-sm {
    border-radius: 10px;
  }
  &:hover {
    filter: brightness(0.8);
    // background: #b8b6b6 !important;
  }
  &:active {
    filter: brightness(0.6);
  }
  &.text-black {
    color: #fff !important;
  }
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@keyframes premium-bounce {
  0%,
  40%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  5% {
    transform: translate3d(-4px, -2px, 0);
  }

  10% {
    transform: translate3d(-3px, -1px, 0);
  }

  12% {
    transform: translate3d(-4px, 2px, 0);
  }
  20% {
    transform: translate3d(0px, 0px, 0);
  }
}

.premium-bounce {
  animation: premium-bounce 5s linear infinite;
}

.glow-background {
  background-color: #121212;
  border-radius: 18px;
}
.glow-background-light {
  background: #fdfafa;
  border-radius: 18px;
}

.fg-container {
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding: 5px 20px 5px 20px;
}

.fg-widget {
  @include themify($themes) {
    border: 1px solid themed('fgWidget');
    a {
      color: themed('textColor');
      font-size: 13px;
    }
  }
  border-radius: 50px;
  // padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  // padding-top: px;
  // padding-bottom: 5px;
}

.dark-text {
  color: #000;
}
.light-text {
  color: #fff;
}
.disclaimer-text {
  h2 {
    font-size: 18px;
    font-weight: 700;
  }

  line-height: 1.5;
  h3 {
    font-size: 18px;
    font-weight: 400;
  }
}

.disabled-false {
  background-color: #007aff !important;
  cursor: pointer;
}

.btn-dark:hover {
  color: #fff;
}
.btn-light:hover {
  color: #000;
}
@media only screen and (max-width: 480px) {
  mbl-greed-up {
    // flex: none;
    gap: 0px I !important;
  }
  fg-widget {
    flex: 50%;
  }
  //    .btn-mobile {
  //     display: hide !important;
  //    }

  .depo__token-list {
    font-size: 12px;
  }
}
