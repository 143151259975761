@import '../../theme-variable.scss';

.fg-container {
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding: 5px 20px 5px 20px;
}

.fg-widget {
  @include themify($themes) {
    border: 1px solid themed('fgWidget');
    a {
      color: themed('textColor');
      font-size: 13px;
    }
  }
  border-radius: 50px;
  // padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  // padding-top: px;
  // padding-bottom: 5px;
}

.dark-text {
  color: #000;
}
.light-text {
  color: #fff;
}
.disclaimer-text {
  h2 {
    font-size: 18px;
    font-weight: 700;
  }

  line-height: 1.5;
  h3 {
    font-size: 18px;
    font-weight: 400;
  }
}

.disabled {
  opacity: 0.5;
  background-color: #525253 !important;
}
.disabled-false {
  background-color: #007aff !important;
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
  mbl-greed-up {
    // flex: none;
    gap: 0px I !important;
  }
  fg-widget {
    flex: 50%;
  }
  //    .btn-mobile {
  //     display: hide !important;
  //    }

  .depo__token-list {
    font-size: 12px;
  }
}

.ant-modal {
  width: 500px;
  flex-shrink: 0;
  background-color: transparent;
  padding: 0 !important;
  border-radius: 10px;
  border: 1px solid transparent;
  // background: #091835, lightgray 50% / cover no-repeat, #0b1e3c;
  // box-shadow: 0px 4px 20px 0px rgba(2, 14, 27, 0.4);
}
