.depo__slider {
  z-index: 1;
  // .ant-slider-mark{
  //   display: none !important;
  // }

  .ant-slider-rail {
    // background-color: var(--brand-lighter-alpha-10) !important;
    background-color: var(--brand-lighter-alpha) !important;
    height: 4px !important;
    top: 4px;
  }

  .ant-slider-track {
    background-color: var(--brand-success) !important;
    height: 1px;
    top: 5px;
  }

  .ant-slider-handle {
    background-color: var(--brand-success) !important;
    border: none;
  }

  .ant-slider-step {
    .ant-slider-dot {
      background-color: var(--brand-secondary);
      border-color: var(--brand-secondary);
      &-active {
        background-color: var(--brand-success) !important;
        border-color: var(--brand-success) !important;
      }
    }
  }

  .ant-slider-mark-text {
    font-size: 14px !important;
  }
}

.depo__slider__light {
  .ant-slider-rail {
    background: blue;
    height: 4px !important;
    top: 4px;
  }

  .ant-slider-track {
    background-color: rgb(5 5 5 / 10%) !important;
    background-size: 200% 200%;
    height: 1px;
    top: 5px;
  }

  .ant-slider-handle {
    background-color: var(--brand-success) !important;
    border: none;
  }

  .ant-slider-step {
    .ant-slider-dot {
      background-color: var(--brand-secondary);
      border-color: var(--brand-secondary);

      &-active {
        background-color: var(--brand-success) !important;
        border-color: var(--brand-success) !important;
      }
    }
  }

  .ant-slider-mark-text {
    font-size: 12px !important;
    font-style: normal;
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px #ff00de, 0 0 10px #ff00de;
  }
  50% {
    box-shadow: 0 0 20px #ff00de, 0 0 30px #ff00de;
  }
  100% {
    box-shadow: 0 0 5px #ff00de, 0 0 10px #ff00de;
  }
}

.depo__slider-ai-mode {
  z-index: 1;

  .ant-slider-rail {
    background-color: var(--brand-lighter-alpha-10) !important;
    height: 4px !important;
    top: 4px;
    position: relative;
    z-index: 0;
    border-radius: 50px;

    &:before {
      content: '';
      background: linear-gradient(
        45deg,
        #0a33ff,
        #23aeff,
        #3863ff,
        #448fff,
        #0095ff,
        #002bff,
        #1100ff,
        #0084ff,
        #0084ff
      );
      position: absolute;
      top: -2px;
      left: -2px;
      background-size: 400%;
      z-index: -1;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      animation: glowing 20s linear infinite;
      opacity: 1;
      transition: opacity 0.3s ease-in-out !important;
      border-radius: 80px;
    }

    &:hover:before {
      opacity: 1;
    }
  }

  .ant-slider-step {
    .ant-slider-dot {
      background-color: white;
      border-color: rgb(168, 168, 168);

      &-active {
        background-color: var(--brand-success) !important;
        border-color: var(--brand-success) !important;
      }
    }
  }
  .ant-slider-track {
    background-color: rgb(255, 255, 255) !important;
    background-size: 200% 200%;
    height: 2px;
    top: 9px;
  }
  .ant-slider-handle {
    background-color: #fff !important;
    border: 1px solid;
    border-color: grey;
  }
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.shadow-wrapper {
  position: relative;
  overflow: hidden;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 50px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 200% 100%;
    animation: gradient 2s linear infinite;
    pointer-events: none;
  }

  &:before {
    background: transparent;
    animation: gradient-purple 2s linear infinite;
    box-shadow: 0 0 5px #33a3ff3c, 0 0 10px #33c5ff3c;
  }

  &:after {
    background: transparent;
    animation: gradient-blue 2s linear infinite;
    box-shadow: 0 0 5px #33aaff, 0 0 10px #33aaff;
    animation-delay: 1s;
  }
}

@keyframes gradient-purple {
  0% {
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.192),
      rgba(255, 255, 255, 0.192),
      transparent
    );
    background-size: 200% 100%;
    background-position: 100% 0;
  }
  100% {
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.192),
      rgba(255, 255, 255, 0.192),
      transparent
    );
    background-size: 200% 100%;
    background-position: -100% 0;
  }
}

@keyframes gradient-blue {
  0% {
    background: linear-gradient(
      90deg,
      transparent,
      rgba(0, 0, 255, 0.268),
      rgba(0, 0, 255, 0.268),
      transparent
    );
    background-size: 200% 100%;
    background-position: 100% 0;
  }
  100% {
    background: linear-gradient(
      90deg,
      transparent,
      rgba(0, 0, 255, 0.268),
      rgba(0, 0, 255, 0.268),
      transparent
    );
    background-size: 200% 100%;
    background-position: -100% 0;
  }
}

.depo__slider-props {
  &--Buy {
    .ant-slider-rail {
      background-color: #363636 !important;
      height: 1px !important;
      top: 5px;
    }

    .ant-slider-track {
      background-color: var(--brand-success) !important;
      height: 1px;
      top: 5px;
    }

    .ant-slider-handle {
      background-color: var(--brand-success) !important;
      height: 24px;
      width: 24px;
      margin-top: -10px;
      border: none;
    }

    .ant-slider-mark-text {
      font-size: 12px !important;
    }

    .ant-slider-step {
      .ant-slider-dot {
        background-color: var(--brand-secondary);
        border-color: var(--brand-secondary);
        &-active {
          background-color: var(--brand-success) !important;
          border-color: var(--brand-success) !important;
        }
      }
    }
  }

  &--Sell {
    .ant-slider-rail {
      background-color: #363636 !important;
      height: 1px !important;
      top: 5px;
    }

    .ant-slider-track {
      background-color: var(--brand-danger) !important;
      height: 1px;
      top: 5px;
    }

    .ant-slider-handle {
      background-color: var(--brand-danger) !important;
      border: none;
    }

    .ant-slider-mark-text {
      font-size: 12px !important;
    }

    .ant-slider-step {
      .ant-slider-dot {
        background-color: var(--brand-secondary);
        border-color: var(--brand-secondary);
        &-active {
          background-color: var(--brand-danger) !important;
          border-color: var(--brand-danger) !important;
        }
      }
    }
  }
}
