.white {
  color: white;
}

.blue {
  color: #2d2db3;
}

.gray {
  color: #c9c2c2;
}

.error-container {
  // background-image: url('../../assets/bg/bg-dark.jpg');
  // background-size: cover;
  background: linear-gradient(180deg, #000D20 0%, #000810 100%);
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.vpn-img-wrapper {
  background-image: linear-gradient(-45deg, #720b93, #0f14ab);
  border-radius: 5px;
  margin-top: 100px;
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-wrapper {
  padding: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  width: 450px;
}
.purple-effect {
  background: radial-gradient(circle, rgba(0, 122, 255, 0.8827906162) 0%, rgb(0 122 255 / 0%) 59%, rgb(0 122 255 / 0%) 100%);
  margin: 0 auto;
  z-index: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  position: absolute;
  opacity: 0.3;
}
.inner-purple-effect {
  background: radial-gradient(circle, rgba(117, 5, 204, 0.5) 0%, rgba(0, 122, 255, 0) 50%, rgba(0, 212, 255, 0) 100%);
  margin: 0 auto;
  z-index: 0;
  width: 500px;
  height: 500px;
  border-radius: 100%;
  position: absolute;
  top: 51px;
  opacity: .7;
}

.error-header {
  color: #EEEEF0;
  font-family: Nunito Sans;
  font-size: 36px;
  font-weight: 700;
  line-height: 49.1px;
  letter-spacing: -0.0075em;
  text-align: center;
}

.error-sub-header{
  //styleName: Body Large Regular;
  font-family: Nunito Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 24.55px;
  text-align: center;
  color: #afc0d4;
}

.error-code {
  margin-bottom: 20px;
  color: #2d2db3;
}

.error-content {
  //styleName: Body Small Regular;
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.1px;
  text-align: center;
  text-align: center;
  color: #afc0d4;
  .error-code {
    color: #007AEB;
  }
  margin-top: 25px;
}

.effect1 {
  background: #7505CC80;
  mix-blend-mode: plus-lighter;
  top: 84px;
  position: absolute;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 50%;
  opacity: 0.2;
}

.effect2 {
  background: #007AFF4D;
  top: 0;
  position: absolute;
  mix-blend-mode: plus-lighter;
  margin: 0 auto;
  width: 700px;
  height: 700px;
  gap: 0px;
  opacity: 0.1;

}