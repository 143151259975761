@font-face {
  font-family: gilroy-bold;
  font-style: normal;
  font-weight: 400;
  src: local('Gilroy-Bold'), url('./fonts/Gilroy-Bold.woff') format('woff')
}

@font-face {
  font-family: gilroy-heavy;
  font-style: normal;
  font-weight: 400;
  src: local('Gilroy-Heavy'), url('./fonts/Gilroy-Heavy.woff') format('woff')
}

@font-face {
  font-family: gilroy-light;
  font-style: normal;
  font-weight: 400;
  src: local('Gilroy-Light'), url('./fonts/Gilroy-Light.woff') format('woff')
}

@font-face {
  font-family: gilroy-medium;
  font-style: normal;
  font-weight: 400;
  src: local('Gilroy-Medium'), url('./fonts/Gilroy-Medium.woff') format('woff')
}

@font-face {
  font-family: gilroy-regular;
  font-style: normal;
  font-weight: 400;
  src: local('Gilroy-Regular'), url('./fonts/Gilroy-Regular.woff') format('woff')
}

@font-face {
  font-family: gilroy;
  font-style: normal;
  font-weight: 900;
  src: local('Gilroy'), url('./fonts/GilroyHeavy.woff') format('woff')
}

@font-face {
  font-family: nunito sans;
  font-style: normal;
  font-weight: 400;
  src: local('Nunito Sans'), url('./fonts/NunitoSans-Regular.woff') format('woff')
}

@font-face {
  font-family: nunito sans;
  font-style: italic;
  font-weight: 400;
  src: local('Nunito Sans'), url('./fonts/NunitoSans-Italic.woff') format('woff')
}

@font-face {
  font-family: nunito sans;
  font-style: normal;
  font-weight: 200;
  src: local('Nunito Sans'), url('./fonts/NunitoSans[YTLC,opsz,wdth,wght].woff') format('woff')
}

@font-face {
  font-family: nunito sans;
  font-style: italic;
  font-weight: 200;
  src: local('Nunito Sans'), url('./fonts/NunitoSans-Italic[YTLC,opsz,wdth,wght].woff') format('woff')
}

@font-face {
  font-family: nunito sans;
  font-style: normal;
  font-weight: 275;
  src: local('Nunito Sans'), url('./fonts/NunitoSans-ExtraLight.woff') format('woff')
}

@font-face {
  font-family: nunito sans;
  font-style: italic;
  font-weight: 275;
  src: local('Nunito Sans'), url('./fonts/NunitoSans-ExtraLightItalic.woff') format('woff')
}

@font-face {
  font-family: nunito sans;
  font-style: normal;
  font-weight: 300;
  src: local('Nunito Sans'), url('./fonts/NunitoSans-Light.woff') format('woff')
}

@font-face {
  font-family: nunito sans;
  font-style: italic;
  font-weight: 300;
  src: local('Nunito Sans'), url('./fonts/NunitoSans-LightItalic.woff') format('woff')
}

@font-face {
  font-family: nunito sans;
  font-style: normal;
  font-weight: 600;
  src: local('Nunito Sans'), url('./fonts/NunitoSans-SemiBold.woff') format('woff')
}

@font-face {
  font-family: nunito sans;
  font-style: italic;
  font-weight: 600;
  src: local('Nunito Sans'), url('./fonts/NunitoSans-SemiBoldItalic.woff') format('woff')
}

@font-face {
  font-family: nunito sans;
  font-style: normal;
  font-weight: 700;
  src: local('Nunito Sans'), url('./fonts/NunitoSans-Bold.woff') format('woff')
}

@font-face {
  font-family: nunito sans;
  font-style: italic;
  font-weight: 700;
  src: local('Nunito Sans'), url('./fonts/NunitoSans-BoldItalic.woff') format('woff')
}

@font-face {
  font-family: nunito sans;
  font-style: normal;
  font-weight: 800;
  src: local('Nunito Sans'), url('./fonts/NunitoSans-ExtraBold.woff') format('woff')
}

@font-face {
  font-family: nunito sans;
  font-style: italic;
  font-weight: 800;
  src: local('Nunito Sans'), url('./fonts/NunitoSans-ExtraBoldItalic.woff') format('woff')
}

@font-face {
  font-family: nunito sans;
  font-style: normal;
  font-weight: 900;
  src: local('Nunito Sans'), url('./fonts/NunitoSans-Black.woff') format('woff')
}

@font-face {
  font-family: nunito sans;
  font-style: italic;
  font-weight: 900;
  src: local('Nunito Sans'), url('./fonts/NunitoSans-BlackItalic.woff') format('woff')
}

@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 400;
  src: local('Inter'), url('./fonts/Inter[slnt,wght].woff') format('woff')
}

@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 400;
  src: local('Inter'), url('./fonts/Inter-Regular.woff') format('woff')
}

@font-face {
  font-family: inter;
  font-style: italic;
  font-weight: 400;
  src: local('Inter'), url('./fonts/Inter-Italic.woff') format('woff')
}

@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 100;
  src: local('Inter'), url('./fonts/Inter-Thin-BETA.woff') format('woff')
}

@font-face {
  font-family: inter;
  font-style: italic;
  font-weight: 100;
  src: local('Inter'), url('./fonts/Inter-ThinItalic-BETA.woff') format('woff')
}

@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 200;
  src: local('Inter'), url('./fonts/Inter-ExtraLight-BETA.woff') format('woff')
}

@font-face {
  font-family: inter;
  font-style: italic;
  font-weight: 200;
  src: local('Inter'), url('./fonts/Inter-ExtraLightItalic-BETA.woff') format('woff')
}

@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 300;
  src: local('Inter'), url('./fonts/Inter-Light-BETA.woff') format('woff')
}

@font-face {
  font-family: inter;
  font-style: italic;
  font-weight: 300;
  src: local('Inter'), url('./fonts/Inter-LightItalic-BETA.woff') format('woff')
}

@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 500;
  src: local('Inter'), url('./fonts/Inter-Medium.woff') format('woff')
}

@font-face {
  font-family: inter;
  font-style: italic;
  font-weight: 500;
  src: local('Inter'), url('./fonts/Inter-MediumItalic.woff') format('woff')
}

@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 600;
  src: local('Inter'), url('./fonts/Inter-SemiBold.woff') format('woff')
}

@font-face {
  font-family: inter;
  font-style: italic;
  font-weight: 600;
  src: local('Inter'), url('./fonts/Inter-SemiBoldItalic.woff') format('woff')
}

@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 700;
  src: local('Inter'), url('./fonts/Inter-Bold.woff') format('woff')
}

@font-face {
  font-family: inter;
  font-style: italic;
  font-weight: 700;
  src: local('Inter'), url('./fonts/Inter-BoldItalic.woff') format('woff')
}

@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 800;
  src: local('Inter'), url('./fonts/Inter-ExtraBold.woff') format('woff')
}

@font-face {
  font-family: inter;
  font-style: italic;
  font-weight: 800;
  src: local('Inter'), url('./fonts/Inter-ExtraBoldItalic.woff') format('woff')
}

@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 900;
  src: local('Inter'), url('./fonts/Inter-Black.woff') format('woff')
}

@font-face {
  font-family: inter;
  font-style: italic;
  font-weight: 900;
  src: local('Inter'), url('./fonts/Inter-BlackItalic.woff') format('woff')
}