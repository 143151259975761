@import '../../theme-variable.scss';
.layout-header-menu {
  display: flex;
  align-items: center;
}
.floating-menu {
  position: fixed;
  bottom: 10px;
  right: 40px;
  padding: 10px;
  border-radius: 5px;
  z-index: 100;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
#leftnav {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}
#leftnav a {
  position: fixed;
  left: 0px;
  top: 40%;
  transition: 0.3s; /* Add transition on hover */
  padding: 20px 10px; /* 15px padding */
  text-decoration: none; /* Remove underline */
  font-size: 13px; /* Increase font size */
  color: white; /* White text color */
  border-radius: 0 5px 5px 0; /* Rounded corners on the top right and bottom right side */
  z-index: 999999;
  display: flex;
  height: 227px;
  align-items: center;
  justify-content: center;
}
#leftnav a:hover {
  left: 0;
}
#rightnav {
  writing-mode: vertical-lr;
  text-transform: rotate(90deg);
}
#rightnav a {
  position: fixed;
  right: 0px;
  top: 40%;
  transition: 0.3s; /* Add transition on hover */
  padding: 20px 10px; /* 15px padding */
  text-decoration: none; /* Remove underline */
  font-size: 13px; /* Increase font size */
  color: white; /* White text color */
  border-radius: 5px 0px 0px 5px; /* Rounded corners on the top right and bottom right side */
  z-index: 999999;
  display: flex;
  height: 227px;
  align-items: center;
  justify-content: center;
}
#rightnav a:hover {
  right: 0;
}
.chatnavLeft {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  letter-spacing: 0.5em;
}
.chatnavRight {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  letter-spacing: 0.5em;
}
.btn-float-left {
  background-color: #0f2f4c;
  position: fixed;
  // left: -5%;
  left: 0%;
  top: 50%;
  z-index: 999999;
  width: 25px;
  cursor: pointer;
  color: #f1f1f1;
  white-space: nowrap;
  font-size: 18px;
  transform: rotate(90deg);
}
.dropdown-button {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  bottom: 100%;
  right: 0;
  background-color: #f1f1f1;
  border-radius: 20px;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}
.dropdown-button:hover .dropdown-content {
  display: block;
}
.ant-switch-checked {
  background-color: #0f2f4c !important;
}
.depo__headers {
  display: flex;
  justify-content: space-between;
  // padding: 16px;
  font-weight: 300 !important;
  // .ant-btn {
  //   background: none !important;
  // }
  .depo_balance_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    flex-wrap: wrap;
    .gas-fee {
      height: 35px;
      display: flex;
      align-items: center;
      // background: #212121 !important;
      background: transparent;
      border-radius: 18px;
      margin-right: 10px;
      @include themify($themes) {
        color: themed('textColor');
      }
      padding: 0 10px;
    }
  }
  .metamask {
    background: transparent;
    border: 1px solid #007aff;
    @include themify($themes) {
      color: themed('textColor') !important;
    }
    height: 35px;
    border-radius: 18px;
  }
  .metamask-balance {
    min-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-metamask-onboarding {
    font-size: 14px !important;
    // background: #383838 !important;
    background: transparent !important;
  }
  .btn-arc-metamask-onboarding {
    font-size: 14px !important;
    background: var(--brand-success) !important;
  }
  .toggle-btn-section {
    margin: auto 0 auto auto;
    .toggle-checkbox {
      justify-content: center;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      .toggle-btn__input {
        height: 0;
        width: 0;
        visibility: hidden;
      }
      .toggle-btn__input-label {
        cursor: pointer;
        text-indent: -9999px;
        width: 46px;
        height: 20px;
        display: block;
        border-radius: 23px;
        position: relative;
        border: none;
        margin-top: 2px;
        @include themify($themes) {
          background-color: themed('toggleBackground');
          color: themed('textColor');
        }
        &::after {
          content: '?';
          position: absolute;
          top: 3px;
          left: 3px;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          transition: 0.3s;
          box-shadow: 0 5px 10px rgba(153, 153, 153, 0.4);
          background-color: #fff;
        }
      }
      .toggle-btn__input:checked ~ .toggle-btn__input-label {
        &:after {
          background: #fff;
          left: calc(100% - 3px);
          transform: translateX(-100%);
        }
      }
      .toggle-btn__input:checked ~ .toggle-btn__theme {
        &:after {
          // theme switch circle background in light theme
          background: #fff;
          left: calc(100% - 3px);
          transform: translateX(-100%);
        }
      }
    }
    .toggle-btn__input:checked ~ .toggle-btn__input-label {
      background-color: #82deff;
    }
  }
}
.arc_header_menu {
  .ant-btn:not([disabled]):hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
  .menu-active {
    // filter: brightness(2);
    color: #0072ff !important;
    // @include themify($themes) {
    //   border-bottom: themed('textColor') 2px solid !important;
    // }
  }
  .btn-arc-menu {
    @include themify($themes) {
      color: themed('textColor');
    }
    font-size: 16px;
    background-color: transparent;
    font-weight: lighter;
    border: none;
    border-radius: 10px;
    font-style: normal;
    margin-left: 3em;
    .ant-btn:active {
      background: none !important;
    }
  }
}
.arc_left_menu {
  // text-align: left;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
  .arc_left_menu_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    span {
      width: auto;
      padding-left: 8px;
    }
    span {
      @include themify($themes) {
        color: themed('arcMenuLeftbarTextColor') !important;
      }
    }
  }
  .ant-btn:not([disabled]):hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
  .btn-arc-menu {
    @include themify($themes) {
      color: themed('arcMenuLeftbarTextColor') !important;
    }
    font-size: 16px;
    font-family: Gilroy-Medium;
    background-color: transparent;
    font-weight: lighter;
    border: none !important;
    border-radius: 10px;
    font-style: normal;
    .ant-btn:active {
      background: none !important;
    }
  }
  .menu-active {
    filter: brightness(2);
  }
}
.arc_leftsidebar_subscription {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  margin-bottom: 16px;
  cursor: pointer;
  span {
    padding-left: 8px;
  }
}
.arc_leftsidebar_wallet_card {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .btn-connect-right-inside {
    height: 56px !important;
  }
  .ant-card {
    height: 56px !important;
    @include themify($themes) {
      background-color: themed('arcMenuLeftbarWalletBgColor') !important;
    }
    .ant-card-body {
      padding: 12px 16px !important;
      height: 100%;
      display: flex;
      align-items: center;
      .card-profile {
        width: 100%;
        align-items: center;
      }
    }
  }
  .token-list {
    display: flex;
    justify-content: flex-start;
    height: 68px;
    padding-top: 14px;
    padding-bottom: 14px;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    img {
      width: 40px;
      height: 40px;
    }
    .token-list-2 {
      display: grid;
      grid-auto-rows: auto;
      flex-grow: 1;
      margin-left: 10px;
      .token-list-2-title {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        letter-spacing: 0.269px;
      }
      .token-list-2-subtitle {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 142%; /* 19.88px */
        letter-spacing: 0.235px;
      }
    }
    .token-list-3 {
      text-align: right;
    }
  }
  .token-list:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}
.arc_leftsidebar_footer {
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 24px;
  border-radius: 20px 20px 0px 0px;
  border: 1px solid #213474;
  background: url('../../assets/menu_subscription_button_bg.svg'), #0b1e3c;
  // background: url(../../assets/menu_subscription_button_bg.svg), lightgray -112px -537px / 158.974% 746.988% no-repeat, #0B1E3C;
  box-shadow: 0px 4px 20px 0px rgba(2, 14, 27, 0.4);
  cursor: pointer;
  .title {
    font-family: Gilroy-SemiBold;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
  }
  .description {
    color: #afc0d4;
    font-family: Gilroy-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}
.btn-ham-menu {
  .ant-btn {
    background: none !important;
  }
  @include themify($themes) {
    color: themed('textColor') !important;
  }
  padding-right: 0px !important;
  font-size: 16px;
  background-color: transparent;
  font-weight: lighter;
  border: none !important;
  border-radius: 10px;
  font-style: normal;
  .ant-btn:active {
    background: none !important;
  }
  .ant-btn:hover {
    background: none !important;
  }
  svg {
    width: 20px;
    height: 20px;
  }
}
.arc_header_menu_footer {
  display: flex;
  justify-content: center;
  .ant-btn:not([disabled]):hover {
    text-decoration: none;
    background-color: transparent !important;
  }
  .btn-arc-menu {
    @include themify($themes) {
      color: themed('textColor') !important;
    }
    font-size: 16px;
    background-color: transparent;
    font-weight: lighter;
    border: none !important;
    border-radius: 10px;
    font-style: normal;
  }
  .menu-active {
    filter: brightness(2);
  }
  margin: 0 auto;
}
.arc_header_menu-left {
  display: flex;
}
.arc_header_menu-right {
  display: flex;
  align-items: center;
}
.arc_right_side_menu {
  flex-direction: row;
}
.btn-wallet {
  @include themify($themes) {
    color: themed('btnWallet') !important;
  }
  background: transparent !important;
  border: 1px solid #007aff;
  font-weight: 500;
  border-radius: 10px;
  &.btn-lg {
    height: 40px !important;
    font-size: 1.6em;
    min-width: 60%;
  }
  &.btn-md {
    height: 45px !important;
    font-size: 1.3em;
  }
  &.btn-sm {
    height: 40px !important;
    font-size: 1.2em;
  }
  &.rounded {
    border-radius: 18px;
  }
  &.rounded-sm {
    border-radius: 10px;
  }
  &:hover {
    filter: brightness(0.8);
  }
  &:active {
    filter: brightness(0.6);
  }
  &.text-black {
    color: #000 !important;
  }
}
.arc_headers {
  justify-content: center;
  align-items: center;
  // display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px;
  gap: 4px;
  margin-top: 10px;
  border-radius: 16px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  .space-bg {
    @include themify($themes) {
      background: themed('spaceBg') !important;
    }
    border-radius: 16px;
    height: 36px;
  }
  .btn-mnu-btn {
    text-align: center;
    background: none !important;
    // display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 10px 6px;
    gap: 8px;
    width: 90px;
    height: 28px;
    font-size: 14px !important;
    font-weight: 400 !important;
    /* Neutral/800
#2D2D31
*/
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .btn-active {
    @include themify($themes) {
      background: themed('buttonActive') !important;
    }
  }
  .btn-mnu-btn:active {
    background: #212121;
    border-radius: 16px;
  }
  // .metamask {
  //   background: #212121 !important;
  //   color: #fff !important;
  //   height: 35px;
  //   border-radius: 18px;
  // }
  // .btn-metamask-onboarding {
  //   font-size: 14px !important;
  //   background: #383838 !important;
  // }
}
.arc-rightbar-dialog {
  // z-index of WalletConnect Modal is 89. WalletConnect Modal
  // should be placed in front of everything.
  z-index: 88;
  overflow: hidden;
  background-color: transparent;
  position: fixed;
  // overflow-y: scroll;
  display: block;
  top: 0;
  bottom: 0;
  right: 0px;
  height: 100vh;
  width: 0vw;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.arc-rightbar-dialog.show {
  width: 100vw;
  -webkit-transition: opacity 0.3s linear, right 0.5s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.5s ease-out;
  -o-transition: opacity 0.3s linear, right 0.5s ease-out;
  transition: opacity 0.3s linear, right 0.5s ease-out;
}

.arc-rightsiderbar-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: calc(100%);
  overflow: hidden;
  right: 8px;
  width: 100%;
  background-color: #9ea2b199;
  transition: background-color 250ms ease 0s, margin 250ms ease 0s;
  &:hover {
    background-color: #9ea2b199;
  }
  .arc-rightsidebar-drawer {
    text-decoration: none;
    cursor: pointer;
    height: 100%;
    padding: 24px calc(28px) 24px 14px;

    width: calc(100% - 320px);
    // background-color: transparent;
  }
  .ant-btn:hover {
    @include themify($themes) {
      background-color: themed('hoverConnectButton') !important;
    }
    // background-color:  rgb(75 85 96 / 99%) !important
  }
}
.arc-rightsidebar-drawer {
  .close {
    background-color: transparent;
    border: none;
  }
}
.arc-rightsidebar-drawer:hover {
  z-index: 1;
  margin: 0px 0px 0px 0px;
  // background-color: #9ea2b199;
}
.arc-rightsidebar-main {
  z-index: 99;
  padding: 20px 32px 20px 32px;
  margin-right: 0px;
  height: 100%;
  overflow: hidden;
  // border-radius: 12px;
  width: 320px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  font-size: 16px;

  @include themify($themes) {
    background: themed('RightBackground');
    box-shadow: themed('profileBackShadow');
  }

  //  background: linear-gradient(180deg, #001833 0%, #000000 100%) !important;
  border: 1px solid rgba(152, 161, 192, 0.24);
  // box-shadow: rgba(255, 255, 255, 0.24) 12px 16px 24px,
  //   rgba(0, 0, 0, 0.24) 12px 8px 12px, rgba(0, 0, 0, 0.32) 4px 4px 8px;
  transition: margin-right 250ms ease 0s;
  .arc-rightsidebar-main-title {
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    display: flex;
    padding: 0px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 20px;
    @include themify($themes) {
      color: themed('textColor') !important;
    }
    h2 {
      font-family: 'Gilroy-Light' !important;
      font-size: 18px;
      font-weight: 400;
      margin: 0;
      @include themify($themes) {
        color: themed('textColor') !important;
      }
    }
    svg {
      cursor: pointer;
    }
  }
  .arc-rightsidebar-main-notification {
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    svg {
      margin-top: 5px;
    }
    span {
      width: 85%;
    }
  }
  .card-profile {
    display: flex;
    justify-content: space-between;
    @include themify($themes) {
      color: themed('profileColor') !important;
    }
  }
  .no-token-balance {
    border: 1px solid #007aff;
    border-radius: 10px;
    padding: 0.3em 1em;
    text-wrap: wrap;
    // text-align: center;
  }
  .ant-card-meta-title {
    @include themify($themes) {
      color: themed('profileColor') !important;
    }
  }
  .ant-card-meta-title:hover {
    background: rgba(152, 161, 192, 0.08);
    cursor: pointer;
  }
  .ant-card-actions {
    background: transparent !important;
    border-top: none !important;
  }
  .ant-card-actions > li > span > .anticon {
    display: inline-block;
    width: 100%;
    @include themify($themes) {
      color: themed('textColor') !important;
    }
    line-height: 22px;
    transition: color 0.3s;
  }
  .token-list {
    animation: 250ms ease-in 0s 1 normal;
    width: 100%;
    display: flex;
    padding: 0px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    padding: 5px;
    font-family: 'Gilroy-Light' !important;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
    }
    @include themify($themes) {
      color: themed('textColor') !important;
    }
    .token-list-1 {
      position: relative;
      top: 0px;
      left: 0px;
    }
    .token-list-img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .token-list-2 {
      display: grid;
      grid-auto-rows: auto;
      -webkit-box-flex: 1;
      flex-grow: 1;
      margin-left: 5px;
      .token-list-2-title {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
      }
      .token-list-2-subtitle {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        font-weight: 400;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .token-list-3 {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: start;
        justify-content: flex-start;
        .token-list-3-value1 {
          box-sizing: border-box;
          margin: 0px;
          min-width: 0px;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: rgb(13, 17, 28);
        }
        .token-list-3-value2 {
          width: 100%;
          display: flex;
          padding: 0px;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: end;
          justify-content: flex-end;
          box-sizing: border-box;
          margin: 0px;
          min-width: 0px;
        }
      }
    }
    .token-list-3 {
      text-align: right;
    }
  }
}

.arc-leftbar-dialog {
  z-index: 999;
  padding: 0px;
  @include themify($themes) {
    background-color: themed('arcMenuLeftbarBgColor') !important;
  }
  position: fixed;
  display: block;
  // top: 0;
  // bottom: 0;
  // left: -2px;
  height: 100%;
  width: 100%;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.arc-leftbar-dialog.show {
  -webkit-transition: opacity 0.3s linear, right 0.5s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.5s ease-out;
  -o-transition: opacity 0.3s linear, right 0.5s ease-out;
  transition: opacity 0.3s linear, right 0.5s ease-out;
  display: block;
}

.arc-leftbar-dialog.hide {
  -webkit-transition: opacity 0.3s linear, right 0.5s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.5s ease-out;
  -o-transition: opacity 0.3s linear, right 0.5s ease-out;
  transition: opacity 0.3s linear, right 0.5s ease-out;
  display: none;
}
.arc-leftsiderbar-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  // right: 8px;
  .arc-leftsidebar-drawer {
    text-decoration: none;
    cursor: pointer;
    height: 100%;
    padding: 24px calc(28px) 24px 14px;
    transition: background-color 250ms ease 0s, margin 250ms ease 0s;
  }
  .ant-btn:hover {
    @include themify($themes) {
      background-color: themed('hoverConnectButton') !important;
    }
    // background-color:  rgb(75 85 96 / 99%) !important
  }
}
.arc-leftsidebar-drawer {
  .close {
    background-color: transparent;
    border: none;
  }
}
.arc-leftsidebar-drawer:hover {
  z-index: 1;
  margin: 0px 0px 0px -8px;
  background-color: rgba(152, 161, 192, 0.08);
  border-radius: 0px 12px 12px 0px;
}
.arc-leftsidebar-main {
  z-index: 99;
  padding: 16px;
  // margin-right: 0px;
  height: 100%;
  overflow: scroll;
  padding-bottom: 200px;
  // border-radius: 12px;
  width: 100%;
  // font-size: 16px;

  // @include themify($themes) {
  //   background: themed('RightBackground');
  //   box-shadow: themed('profileBackShadow');
  // }

  //  background: linear-gradient(180deg, #001833 0%, #000000 100%) !important;
  // border: 1px solid rgba(152, 161, 192, 0.24);
  // box-shadow: rgba(255, 255, 255, 0.24) 12px 16px 24px,
  //   rgba(0, 0, 0, 0.24) 12px 8px 12px, rgba(0, 0, 0, 0.32) 4px 4px 8px;
  transition: margin-left 250ms ease 0s;
  .arc_leftsidebar_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    button {
      background: transparent !important;
      border: 1px solid #676767;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .arc-leftsidebar-main-title {
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    display: flex;
    padding: 0px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 10px;
    @include themify($themes) {
      color: themed('textColor') !important;
    }
    h2 {
      font-family: 'Gilroy-Light' !important;
      font-size: 18px;
      font-weight: 400;
      @include themify($themes) {
        color: themed('textColor') !important;
      }
    }
  }
  .card-profile {
    display: flex;
    justify-content: space-between;
    @include themify($themes) {
      color: themed('profileColor') !important;
    }
  }
  .no-token-balance {
    border: 1px solid #007aff;
    border-radius: 10px;
    padding: 0.3em 1em;
    text-wrap: wrap;
    // text-align: center;
  }
  .ant-card-meta-title {
    @include themify($themes) {
      color: themed('profileColor') !important;
    }
  }
  .ant-card-meta-title:hover {
    background: rgba(152, 161, 192, 0.08);
    cursor: pointer;
  }
  .ant-card-actions {
    background: transparent !important;
    border-top: none !important;
  }
  .ant-card-actions > li > span > .anticon {
    display: inline-block;
    width: 100%;
    @include themify($themes) {
      color: themed('textColor') !important;
    }
    line-height: 22px;
    transition: color 0.3s;
  }
}
.btn-connect-right {
  @include themify($themes) {
    background-color: themed('buttonConnectRight') !important;
  }
  border: none !important;
  border-radius: 10px !important;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 18px;
  transition: all 125ms ease 0s;
  opacity: 1;
  width: 100% !important;
  font-family: 'Gilroy-Light' !important;
  height: auto !important;
  .btn-connect-right-inside {
    display: flex;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    @include themify($themes) {
      color: themed('textColor') !important;
    }
    font-size: 16px;
    height: 65px;
  }
  .btn-right-subtile {
    margin-left: 5px;
  }
}
.footer-left {
  position: fixed;
  bottom: 0;
  left: 0;
  @include themify($themes) {
    color: themed('textColor') !important;
  }
  padding: 10px;
}
.footer-right {
  position: fixed;
  bottom: 0;
  right: 0;
  @include themify($themes) {
    color: themed('textColor') !important;
  }
  padding: 10px;
}
.chatbot {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  width: 70%;
  padding-bottom: 0px;
  z-index: 10000;
}
.chatbot-mobile {
  @include themify($themes) {
    // background-color: themed('chatBackgroundMobile') !important;
  }
}
@media only screen and (max-width: 992px) {
  .footer-left {
    display: none;
  }
  .chatbot {
    width: 90%;
    left: 0;
    transform: translateX(117%);
    text-align: left;
  }
}
@media only screen and (max-width: 480px) {
  .chatbot {
    width: 100%;
    left: 0;
    transform: translateX(100%);
    text-align: left;
  }
  .depo__headers {
    flex-wrap: wrap;
    .mbl {
      display: none;
    }
    .fg-headers {
      flex: 100%;
    }
    .metamask-balance {
      min-width: 100px;
    }
    .depo_balance_wrapper {
      // flex: 100%;
      margin-top: 10px;
      justify-content: center;
      .gas-fee {
        display: none;
      }
    }
  }
}
.chat-left-body-logo-modal {
  position: absolute;
  left: 10%;
  bottom: 20%;
  z-index: 100;
  padding: 20px;
  border-radius: 10px;
}

.social-connect-wrapper {
  .social-connect-header {
  }
  .social-connect-list {
    margin-top: 10px;
    border: 1px solid #444449;
    border-radius: 24px;
    overflow: hidden;
    .social-connect-list-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      background-color: transparent;
      transition: background-color 0.5s;
      cursor: pointer;
      .social-connect-list-row-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img {
          width: 24px;
          margin-right: 10px;
        }
        .social-connect-list-row-info-content {
          line-height: 1.2em;
          p {
            margin: 0;
            font-size: 16px;
          }
          span {
            font-size: 14px;
            opacity: 0.9;
          }
          .tgme_widget_login_button {
            background-color: transparent !important;
            color: black;
          }
        }
      }
      .social-connect-list-row-connected {
        width: 24px;
      }
      &:hover {
        background-color: #001833;
      }
    }
  }
}
.arc-rightsidebar-main-social {
  z-index: 99;
  padding: 30px 0px 20px;
  margin-right: 0px;
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
  width: 400px;
  font-size: 16px;
  position: relative;

  @include themify($themes) {
    background: themed('RightBackground');
    box-shadow: themed('profileBackShadow');
  }
  .arc-rightsidebar-main-title-social-mt4 {
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  .arc-rightsidebar-main-title-social {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    h4 {
      margin: 0;
    }
    margin-bottom: 1.5em;
    svg {
      cursor: pointer;
    }
  }
  .arc-rightsidebar-main-body {
    padding-left: 32px;
    padding-right: 32px;
    h5 {
      margin-bottom: 1em;
      margin-top: 2em;
      font-size: 1.15em !important;
      opacity: 0.8;
    }
    .arc-rightsidebar-main-body-select {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 0.7em;
      .arc-rightsidebar-main-body-select-all-wrapper {
        display: flex;
        padding: 10px 15px;
        align-items: center;
        justify-content: space-between;
        border-radius: 25px;
        border: 1px solid gray;
        cursor: pointer;
        svg {
          margin-right: 10px;
        }
        div {
          font-size: 0.8em;
        }
      }
    }
    .arc-rightsidebar-main-body-select-list {
      display: flex;
      // flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      .arc-rightsidebar-main-body-select-list-item {
        // width: auto;
        margin: 0 0.5em 0.5em 0;
        padding: 10px 16px 10px 14px;
        border: 1px solid gray;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > div {
          margin: 0 0.7em;
          font-size: 0.8em;
          p {
            margin: 0;
            font-size: 0.7em;
          }
        }
      }
      .arc-rightsidebar-main-body-select-list-item.show {
        margin: 0 0.5em 0.5em 0;
        padding: 10px 16px 10px 14px;
        border: 1px solid #007aff;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #007aff2e;

        & > div {
          margin: 0 0.7em;
          p {
            margin: 0;
            font-size: 0.8em;
          }
        }
      }
    }
    .arc-rightsidebar-token-card {
      border-radius: 20px;
      border: 2px solid #007aff;
      overflow: hidden;
      margin-bottom: 1em;
      cursor: pointer;
      transition: 0.5s;
      &:hover {
        transform: scale(1.01, 1.01);
      }
      & > img {
        width: 100%;
        max-height: 113px;
      }
      .social-connect-list-row-info-img {
        width: 100%;
      }
      .arc-rightsidebar-token-card-detail {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 14px 16px;
        svg {
          margin-right: 7px;
          margin-top: 3px;
        }
        .arc-rightsidebar-token-card-info {
          width: calc(100% - 27px);
          h5 {
            font-size: 1.3em !important;
            font-weight: 400;
            margin: 0;
          }
          p {
            font-size: 1em;
            margin: 0;
            font-weight: 200;
            opacity: 0.7;
          }
        }
      }
    }
  }
  .arc-rightsidebar-token-alert {
    h5 {
      margin-bottom: 1em;
      font-size: 1.15em !important;
    }
    .arc-rightsidebar-token-alert-select {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 0.7em;
      .arc-rightsidebar-token-alert-select-all-wrapper {
        display: flex;
        padding: 10px 15px;
        align-items: center;
        justify-content: space-between;
        border-radius: 25px;
        border: 1px solid gray;
        cursor: pointer;
        svg {
          margin-right: 10px;
        }
        div {
          font-size: 0.8em;
        }
      }
      .show {
        border: 1px solid #007aff;
        background: #007aff2e;
      }
    }
  }
  // .arc-rightsidebar-main-social-step-btn {
  //   position: absolute;
  //   padding: 10px 20px;
  //   background-color: #007aff;
  //   color: white !important;
  //   border-radius: 25px;
  //   right: 12px;
  //   bottom: 20px;
  //   cursor: pointer;
  //   border: none;
  //   font-weight: bold;
  // }
  .disabled {
    background-color: #2d2d31;
  }
}
.arc-rightsiderbar-wrapper-social {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: calc(100%);
  overflow: hidden;
  right: 8px;
  width: 100%;
  .arc-rightsidebar-drawer {
    text-decoration: none;
    cursor: pointer;
    height: 100%;
    transition: background-color 250ms ease 0s, margin 250ms ease 0s;
    width: calc(100% - 400px);
    background-color: #9ea2b199;
  }
  .ant-btn:hover {
    @include themify($themes) {
      background-color: themed('hoverConnectButton') !important;
    }
    // background-color:  rgb(75 85 96 / 99%) !important
  }
}
.arc-rightsidebar-token-balancecard {
  border-radius: 20px;
  border: 2px solid #007aff;
  overflow: hidden;
  margin-bottom: 1em;
  margin-top: 1em;
  // cursor: pointer;
  // transition: 0.5s;
  // &:hover {
  //   transform: scale(1.01, 1.01);
  // }
  img {
    width: 100%;
  }
  .arc-rightsidebar-token-card-detail {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 14px 16px 5px;
    svg {
      margin-left: 7px;
      margin-top: 3px;
    }
    .arc-rightsidebar-token-card-info {
      width: calc(100% - 27px);
      h5 {
        font-size: 1.3em !important;
        font-weight: 400;
        margin: 0;
      }
      p {
        font-size: 1em;
        margin: 0;
        font-weight: 200;
        opacity: 0.7;
      }
    }
  }
}
.arc-rightsidebar-token-card-step-btn {
  padding: 3px 20px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  background-color: #007aff;
  color: white !important;
  border-radius: 25px;
  cursor: pointer;
  border: none;
  font-weight: bold;
  margin-bottom: 14px;
  margin-top: 5px;
}
.arc-rightsidebar-subscription-button {
  display: flex;
  height: 48px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 24px;
  border: 4px solid #1b2e5e;
  background: linear-gradient(90deg, #007aff 0%, #480cab 125.88%);
  position: relative;
  cursor: pointer;
  p {
    color: var(--neutral-white, #fff);
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 112.5% */
    margin: 0;
  }
  svg {
    position: absolute;
    right: 18.5px;
    top: 12px;
  }
  margin-top: 20px;
}

@keyframes hide {
  from {
    opacity: 1;
    display: block;
  }
  to {
    opacity: 0;
    display: none;
  }
}
@keyframes show {
  from {
    opacity: 0;
    display: none;
  }
  to {
    opacity: 1;
    display: block;
  }
}
