@import './bg';
@import './flex';
@import './sizing';
@import './text';
@import './container';
@import './spacing';
@import './border';
@import './input';
@import './slider';
@import './tabs';

//  @import url('https://fonts.cdnfonts.com/css/proxima-nova-2');

@import '../theme-variable.scss';
// Chrome, Safari, Edge, Opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
  font-family: 'Gilroy-Light' !important;
  // bodyBackgroundColor
  // background: #000;
  // background: red !important;
}

body,
input,
textarea,
button {
  font-family: 'Gilroy-Light';
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 550;
}

button {
  cursor: pointer;
}
.header {
  z-index: 2;
}
:root {
  --brand-green-light: #63f84b;
  --brand-green: #198907;
  --brand-dark: #070708;
  --brand-dark-b: #111114;
  --brand-dark-b-alpha: rgba(17, 17, 20, 0.9);
  --brand-dark-b-hover: #24242b;
  --brand-dark-b-hover-alpha: rgba(36, 36, 43, 0.9);
  --brand-dark-alpha: #070708af;
  --brand-darker-alpha: #070708e3;
  --brand-lighter-alpha-10: rgba(255, 255, 255, 0.1);
  --brand-lighter-alpha-20: rgba(255, 255, 255, 0.2);
  --brand-lighter-alpha-30: rgba(255, 255, 255, 0.3);
  --brand-lighter-alpha: rgba(172, 96, 96, 0.05);
  --brand-light-alpha: rgba(172, 96, 96, 0.5);
  --brand-success: #007aff;
  --brand-danger: #f34747;
  --brand-secondary: #898989;
  --brand-swap-success: #007aff;
  --brand-swap-secondary: #007aff;
  --brand-info: #4d65ee;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
}
/* default transition */
.deft {
  transition: ease-in-out 200ms;
}

/* GRID MODIFICATION */
.ant-row {
  padding: 0 15px;
}

.ant-col {
  padding: 0.8rem 1rem;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.btn-depo {
  @include themify($themes) {
    color: themed('textColor') !important;
  }
  font-weight: 500;
  border: none !important;
  border-radius: 10px;
  &.btn-lg {
    height: 40px !important;
    font-size: 1.6em;
    min-width: 60%;
  }
  &.btn-md {
    height: 45px !important;
    font-size: 1.3em;
  }
  &.btn-sm {
    height: 35px !important;
    font-size: 1.2em;
  }
  &.rounded {
    border-radius: 18px;
  }
  &.rounded-sm {
    border-radius: 10px;
  }
  &:hover {
    filter: brightness(0.5);
  }
  &:active {
    filter: brightness(0.6);
  }
  &.text-black {
    color: #000 !important;
  }
}

.btn-login {
  color: #007aff !important;

  font-weight: 500;
  border: none !important;
  border-radius: 10px;
  &.btn-lg {
    height: 40px !important;
    font-size: 1.6em;
    min-width: 60%;
  }
  &.btn-md {
    height: 45px !important;
    font-size: 1.3em;
  }
  &.btn-sm {
    height: 35px !important;
    font-size: 1.2em;
  }
  &.rounded {
    border-radius: 18px;
  }
  &.rounded-sm {
    border-radius: 10px;
  }
  &:hover {
    filter: brightness(0.8);
  }
  &:active {
    filter: brightness(0.6);
  }
  &.text-black {
    color: #000 !important;
  }
}

.btn-connect {
  @include themify($themes) {
    color: themed('textColor') !important;
  }
  font-weight: 500;
  border: none !important;
  border-radius: 10px;
  font-family: 'Gilroy-bold';
  &.btn-lg {
    height: 60px !important;
    font-size: 1.2em;
    min-width: 60%;
    font-weight: 550;
    font-family: 'Gilroy-bold';
    box-shadow: 2px 2px 30px rgba(255, 255, 255, 0.2);
  }
  &.btn-md {
    height: 45px !important;
    font-size: 1.3em;
    font-weight: 550;
    font-family: 'Gilroy-bold';
    box-shadow: 2px 2px 30px rgba(255, 255, 255, 0.2);
  }
  &.btn-sm {
    height: 35px !important;
    font-size: 1.2em;
    font-weight: 550;
  }
  &.rounded {
    border-radius: 24px;
  }
  &.rounded-sm {
    border-radius: 24px;
  }
  &.rounded-md {
    border-radius: 36px;
  }
  &:hover {
    // filter: brightness(0.8);
    background: rgb(16, 72, 255) !important;
  }
  &:active {
    filter: brightness(0.6);
  }
  &.text-black {
    color: #000 !important;
  }
}

.btn-top {
  @include themify($themes) {
    color: themed('textColor') !important;
  }
  font-weight: 500;
  border: none !important;
  border-radius: 10px;
  &.btn-lg {
    height: 40px !important;
    font-size: 1.6em;
    min-width: 60%;
  }
  &.btn-md {
    height: 45px !important;
    font-size: 1.3em;
  }
  &.btn-sm {
    // height: 35px !important;
    font-size: 14px;
    font-weight: 100;
    letter-spacing: -0.01em;
    font-style: normal;
  }
  &.rounded {
    border-radius: 18px;
  }
  &.rounded-sm {
    border-radius: 10px;
  }
  &:hover {
    filter: brightness(0.8);
  }
  &:active {
    filter: brightness(0.6);
  }
  &.text-black {
    color: #000 !important;
  }
}

.btn-arc {
  color: white !important;
  background: rgba(0, 122, 255, 1) !important;
  font-weight: 500;
  border: none !important;
  border-radius: 10px;
  &.btn-lg {
    height: 55px !important;
    font-size: 1.6em;
    min-width: 60%;
  }
  &.btn-md {
    height: 45px !important;
    font-size: 1.3em;
  }
  &.btn-sm {
    height: 35px !important;
    font-size: 1.2em;
  }
  &.rounded {
    border-radius: 18px;
  }
  &.rounded-sm {
    border-radius: 10px;
  }
  &:hover {
    filter: brightness(0.8);
  }
  &:active {
    filter: brightness(0.6);
  }
  &.text-black {
    color: #000 !important;
  }
}

.rounded-circle-lr {
  border-radius: 25% !important;
}

.btn-depo-text {
  color: white !important;
  font-weight: 550;
  border: none !important;
  border-radius: 10px;
  &.btn-lg {
    height: 55px !important;
    font-size: 1.6em;
    min-width: 75%;
  }
  &.btn-sm {
    height: 35px !important;

    font-size: 1.2em;
    min-width: 50%;
  }
  &.rounded {
    border-radius: 18px;
  }
  &.rounded-sm {
    border-radius: 10px;
  }
  &:hover {
    filter: brightness(0.8);
  }
  &:active {
    filter: brightness(0.6);
  }
}

.btn-depo-normal {
  color: white !important;
  font-weight: 500;
  border: none !important;
  border-radius: 10px;
  &.btn-lg {
    height: 55px !important;
    font-size: 1.6em;
    min-width: 75%;
  }
  &.btn-sm {
    height: 35px !important;

    font-size: 1.2em;
    min-width: 50%;
  }
  &.rounded {
    border-radius: 18px;
  }
  &.rounded-sm {
    border-radius: 10px;
  }
  &:hover {
    filter: brightness(0.8);
  }
  &:active {
    filter: brightness(0.6);
  }
}
.pointer {
  cursor: pointer;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #000;
  color: var(--brand-success);
}

.ant-menu-item-active,
.ant-menu-item-selected {
  a {
    color: var(--brand-success) !important;
  }
}
.ant-menu-light .ant-menu-item:hover {
  color: var(--brand-success);
  background-color: none;
}

.ant-menu-title-content {
  a {
    color: #fff;
  }
}

.ant-menu-title-content:hover {
  color: var(--brand-success);
  background-color: none;
}

.ant-menu-item {
  background-color: transparent !important;
}

//submenu
.ant-menu-submenu-selected {
  color: var(--brand-success);
  background-color: none;
}

.ant-menu-submenu-title-content:hover {
  color: var(--brand-success);
  background-color: none;
}

//

.ant-menu-submenu-arrow {
  color: var(--white) !important;
}

.ant-layout-sider-trigger {
  background: transparent;
  top: 0;
  margin-top: 10px;
}

.ant-layout-sider-has-trigger {
  padding-bottom: 0;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: transparent;
}

li.ant-menu-item {
  display: flex;
  align-items: center;
}

.ant-menu-item .ant-menu-item-icon {
  min-width: 24px;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: transparent;
  margin-top: 60px;
}

.ant-card {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 10px;
}

.ant-card-head {
  border-bottom: none;
  @include themify($themes) {
    // color: themed('textColor') !important;
  }
  box-sizing: border-box;
}

#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.ant-popover-placement-right .ant-popover-arrow,
.ant-popover-placement-rightTop .ant-popover-arrow,
.ant-popover-placement-rightBottom .ant-popover-arrow {
  width: 0;
  height: 0;
}

.ant-popover-arrow {
  width: 0;
  height: 0;
}

.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  margin-top: 60px;
  width: 0;
  height: 0;
  padding-right: 0;
}

.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 30px;
}

.ant-popover-inner {
  background-color: #111114;
  border-radius: 10px;
}

.ant-popover-inner-content {
  padding: 0;
}

.ant-select {
  // background-color: var(--brand-dark);
  background: transparent;
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
  line-height: 12px;
}

.ant-select-selector {
  height: 100%;
  @include themify($themes) {
    color: themed('textColor') !important;
  }
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: var(--brand-dark);
  color: #fff;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: var(--brand-dark);
  border-radius: 10px;
  border: none;
}

.ant-select-arrow {
  @include themify($themes) {
    color: themed('textColor');
  }
}

// .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
//   background-color: black !important;
// }

// .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
//   background-color: #111114 !important;
//   color: var(--brand-success);
// }

.ant-table {
  background: transparent;
}

.ant-empty-description {
  color: #fff;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 5px 5px 5px 5px;
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: unset; //Change the existing color to `unset`
}

.ant-form-item {
  margin-bottom: 0 !important;
}

.ant-form-item-explain.ant-form-item-explain-error {
  margin-left: 5px;
}

.ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: transparent;
}

.ant-tooltip-inner {
  border-radius: 6px;
  background-color: #333333;
  font-weight: 500;
  font-size: 12px;
  padding: 15px;
}

// .ant-menu-vertical.ant-menu-sub
// .ant-menu-item.ant-menu-item-only-child

.ant-menu-sub {
  // min-width: 0px !important;
  // width: 100px !important;
  padding: 0 10px !important;
  background: var(--brand-dark-b-hover) !important;
  color: white !important;
}

.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-submenu-selected,
.ant-menu-submenu-active {
  color: var(--brand-success) !important;
}
// .ant-menu-submenu-arrow,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: var(--brand-success) !important;
}
.ant-menu-submenu-open .ant-menu-submenu-arrow {
  color: var(--brand-success) !important;
}
.ant-menu-item.ant-menu-item-only-child {
  margin: 0 !important;

  &:first-of-type {
    border-bottom: 1px solid var(--brand-success) !important;
  }
}

.ant-typography {
  @include themify($themes) {
    color: themed('textColor') !important;
  }
}

.depo__tabs_secondary {
  .ant-tabs-tab {
    background-color: transparent !important;
    font-family: 'Gilroy-Light' !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 36px !important;
    display: flex !important;
    align-items: center !important;
    color: rgba(255, 255, 255, 0.67) !important;
    padding: 8px 0 !important;
    // margin-left: 8px !important;
  }

  .ant-tabs-nav::before {
    border: 1px solid #2c2c2c !important;
  }
  .ant-tabs-tab-btn {
    border: none !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #007aff !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 4px solid #007aff !important;
    z-index: 2;
  }
  .ant-tabs-ink-bar {
    border-bottom: 2px solid #007aff !important;
  }
}

.text-theme {
  @include themify($themes) {
    color: themed('textColor') !important;
  }
}

.text-theme-light {
  @include themify($themes) {
    color: themed('lightColorButton') !important;
  }
}
