@import '../theme-variable.scss';

.card-top {
  display: flex;
  align-items: center;
  .card-top-network {
    .ant-popover-title {
      color: #fff !important;
    }
    .ant-dropdown-menu {
      border-radius: 10px !important;
      border: 1px solid;
      // background-color: transparent !important;
      background-color: rgb(12 12 12) !important;
      // @include themify($themes) {
      //   background-color: themed('spaceBg') !important;
      // }
      margin-top: 10px !important;
    }
  }
  .card-top-account {
  }
}
.network-panel {
  margin-top: -5px;
  @include themify($themes) {
    color: themed('textColor') !important;
  }
  font-family: 'Gilroy-Light';
  .ant-btn {
    background: transparent !important;
    border: none;
    @include themify($themes) {
      color: themed('textColor') !important;
    }
  }

  .ant-popover-title {
    color: #fff !important;
  }
  .ant-dropdown-menu {
    border-radius: 10px !important;
    border: 1px solid;
    // background-color: transparent !important;
    background-color: rgb(12 12 12) !important;
    // @include themify($themes) {
    //   background-color: themed('spaceBg') !important;
    // }
    margin-top: 10px !important;
  }
}

.network-select-mainpanel {
  top: 0;
  z-index: 9;
  user-select: none;
  color: #fff;

  .title {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .depo__token-to-mng-list {
    // position: absolute;
    // bottom: -25px;
    width: 98%;
    margin-bottom: 5px;
    // border-bottom-left-radius: 10px;
    // border-bottom-right-radius: 10px;
    background-color: #2d2d31;
    border-radius: 28px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    .toMngList {
      background-color: transparent !important;
      // font-family: Montserrat;
      font-family: 'Gilroy-Light' !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
  }
}
.shimmer {
  color: grey;
  display: inline-block;
  -webkit-mask: linear-gradient(
      -60deg,
      #23aeff 30%,
      rgba(25, 174, 255, 0.333),
      #1100ff 70%
    )
    right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
  font-size: 50px;
  max-width: 200px;
}
.network-card-dropdown {
  .ant-dropdown-menu {
    background: #0b1e3c;
  }
}
.network-card-dropdown-light {
  .ant-dropdown-menu {
    background: #dfdfdf;
  }
}
@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}
