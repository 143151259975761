@import './theme-variable.scss';
@import './styles/antd.scss';
@import url('./font.css');
@import url('https://db.onlinewebfonts.com/c/000833a3a58fb083c44b9de42c2ff0a2?family=Gilroy-SemiBold');

:root {
  --animate-duration: 2s;
}

::-webkit-scrollbar-track {
  background-color: #2c2c2c;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 100px rgb(18, 18, 18);
  border-radius: 5px;
}

::-webkit-scrollbar {
  width: 10px;
}

html {
  min-height: 100%;
  font-family: 'Gilroy' !important;
}
body {
  mix-blend-mode: normal;
  min-height: 100%;
  font-family: 'Gilroy-Medium' !important;
  &.dark {
    background: url('assets/bg/bg-dark.jpg');
    font-family: 'Gilroy-Medium' !important;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  &.light {
    font-family: 'Gilroy-Medium' !important;
    background: #ffffff;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
}

.ant-layout {
  background: transparent;
}

.logo-top {
  padding: 2px 15px;
}
.textColorBlackWhite {
  @include themify($themes) {
    color: themed('textColor') !important;
  }
}

.elapsed-time {
  @include themify($themes) {
    color: themed('textColorBlue') !important;
  }
}
.settingIcons {
  @include themify($themes) {
    color: themed('textColor') !important;
  }
}
.textColor {
  @include themify($themes) {
    color: themed('textColorBlue');
  }
}

.textColorWhite {
  @include themify($themes) {
    color: themed('textColor');
  }
}
.fromTokenField {
  @include themify($themes) {
    color: themed('textColorAmount');
  }
}
.toTokenField {
  @include themify($themes) {
    color: themed('textColor');
  }
}
.toTokenFieldBody {
  @include themify($themes) {
    background-color: themed('toTokenFieldBody');
  }
}

.loadingOutline {
  @include themify($themes) {
    color: themed('loadingOutline');
  }
}
.layout {
  min-height: 95vh;
  @include themify($themes) {
    color: themed('textColor');
  }
}
.ant-layout-footer {
  padding: 0px !important;
  background: transparent;
}
.layout-footer_dark {
  margin-bottom: -40px;
  padding: 15px 50px;
  background: linear-gradient(to top, #000, transparent);
  .text-footer {
    @include themify($themes) {
      color: themed('textColor');
    }
  }
}
.layout-footer_light {
  margin-bottom: -40px;
  padding: 15px 50px;
  background: transparent !important;
  .text-footer {
    @include themify($themes) {
      color: themed('textColor');
    }
  }
}
.layout-header {
  position: fixed;
  top: 0;
  width: 100%;

  // z-index of WalletConnect Modal is 89, z-index of
  // .arc-rightbar-dialog is 88. WalletConnect Modal
  // should be placed in front of everything.
  z-index: 87;
}
.layout-header-menu {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.scroll-up {
  @include themify($themes) {
    background: themed('scrollUp') !important;
  }
}

.sider {
  background: var(--brand-dark);
  color: #fff;
  flex-direction: column;
  z-index: 9;
  overflow: hidden !important;
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
  padding-top: 16px;

  @media screen and (max-width: 1200px) {
    position: fixed;
    padding-top: 8px;
  }
}

.menu {
  background: var(--brand-dark);
  color: #fff;
  margin-top: 0 !important;

  .ant-menu-sub.ant-menu-inline {
    background: transparent !important;
    color: white !important;
  }

  .ant-menu-sub.ant-menu-inline > .ant-menu-item,
  .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    color: white !important;
  }
}

.page-wrapper {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.page-wrapper-dark {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.layoutContent {
  width: 100%;
  overflow: auto;
  min-height: calc(100vh - 218px);
}

.layoutContent::-webkit-scrollbar {
  display: none;
}

.ant-layout-header {
  height: 0px !important;
  line-height: 44px !important;
  background: none;
  padding: 5px 0px 0px 0px;
}
.ant-layout-header.header {
  // background: transparent;
  padding: 0;
  min-height: 64px;
  height: unset;
}
.content {
  background: rgb(0, 0, 0);
  opacity: 0.9;
}

.bg-vignette {
  background: rgba(14, 14, 14, 0.432);
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;

  &-fixed {
    background: linear-gradient(
        90deg,
        rgba(29, 215, 54, 0.1) 0%,
        rgba(0, 0, 0, 0.1) 67%,
        rgba(29, 215, 54, 0.1) 100%
      ),
      #000000;
    mix-blend-mode: normal;
    // background-image: radial-gradient(rgba(0, 0, 0, 0.877), black);
    top: 0;
    left: 0;
    z-index: 0;
    position: fixed !important;
    height: 100vh !important;
    width: 100vw !important;
  }
}

.content-wrapper {
  position: relative;
  z-index: 1;
  margin-top: 105px;
  padding-bottom: 10px;
}
.content-wrapper-mobile {
  position: relative;
  z-index: 1;
  margin-top: 120px;
}

.logoContent {
  position: absolute;
  width: 100%;
  bottom: 20px;
  text-align: center;
}

.logocolapsed {
  filter: invert(55%) sepia(82%) saturate(608%) hue-rotate(80deg)
    brightness(104%) contrast(100%);
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked {
  box-shadow: none !important;
}

.page-title {
  font-weight: 550;
  font-size: 42px;
  line-height: 42px;
  @include themify($themes) {
    color: themed('textColor');
  }
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 992px) {
    font-size: 24px;
  }
}
.footer {
  // position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: transparent;
  text-align: center;
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  text-decoration: none;
  // background: rgb(16, 72, 255) !important;
  // background: none !important;
}
.ant-modal-body {
  padding: 7px !important;
}

.swap-page-wrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.swap-page-content {
  text-align: center;
  width: 100%;
  // height: calc(100vh - 78px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.swap-page-content-audit {
  width: 100%;
  height: calc(100vh - 78px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.swap-page-content-audit::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}
.swap-page-content::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}
.swap-page-container {
  // height: calc(100vh - 78px);
  overflow: hidden;
}
.swap-page-container::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}
@media only screen and (min-width: 360px) and (max-width: 768px) {
  .ant-drawer-body {
    overflow: hidden !important;
  }
  .text-footer {
    display: none;
  }
  body {
    &.dark {
      // background: url("../src/assets/bg/bg-dark-mobile.jpeg")  no-repeat;
    }

    &.light {
      background-size: auto;
    }
  }
}

.ant-popover-title {
  color: #fff !important;
}
.ant-dropdown-menu {
  border-radius: 10px !important;
  // border: 1px solid;
  // background-color: rgb(12 12 12) !important;
  @include themify($themes) {
    background-color: themed('spaceBg') !important;
  }
  // margin-top: 10px !important;
}

// .layout-header-menu .ant-dropdown-menu {
//   margin-top: 0px !important;
// }
