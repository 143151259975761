@import '../../theme-variable.scss';
.dot {
  height: 10px;
  width: 10px;
  background-color: #d9d9d9;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
.dot.active {
  background-color: #007aff !important;
}
.swipeable {
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  opacity: 1;
}

.swipeable.swiped-left {
  transform: translateX(-50%);
  opacity: 0.5;
}

.swipeable.swiped-right {
  transform: translateX(50%);
  opacity: 0.5;
}

.glow-on-hover {
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 50px;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out !important;
  border-radius: 80px;
  display: inherit !important;
}

.glow-on-hover:active {
  color: #000;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
.ai-mode-button {
  font-size: 4rem;

  display: inline-block !important;
  cursor: pointer !important;
  text-decoration: none !important;
  color: var(--clr-neon) !important;
  border: var(--clr-neon) 0.125em solid !important;
  padding: 0.25em 1em !important;
  border-radius: 0.25em !important;

  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em currentColor !important;

  box-shadow: inset 0 0 0.5em 0 var(--clr-neon), 0 0 0.5em 0 var(--clr-neon) !important;

  position: relative !important;
}

.ai-mode-button::before {
  pointer-events: none !important;
  content: '' !important;
  position: absolute !important;
  background: var(--clr-neon) !important;
  top: 120% !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;

  transform: perspective(1em) rotateX(40deg) scale(1, 0.35) !important;
  filter: blur(1em) !important;
  opacity: 0.7 !important;
}

.ai-mode-button::after {
  content: '' !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  box-shadow: 0 0 2em 0.5em var(--clr-neon) !important;
  opacity: 0 !important;
  background-color: var(--clr-neon) !important;
  z-index: -1 !important;
  transition: opacity 100ms linear !important;
}

.ai-mode-button:hover,
.ai-mode-button:focus {
  color: var(--clr-bg) !important;
  text-shadow: none !important;
}

.ai-mode-button:hover::before,
.ai-mode-button:focus::before {
  opacity: 1 !important;
}
.ai-mode-button:hover::after,
.ai-mode-button:focus::after {
  opacity: 1 !important;
}

.coin-info {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px;
  gap: 4px;
  margin-top: 10px;
  border-radius: 16px;
  margin-bottom: 20px;
  .space-bg {
    @include themify($themes) {
      background: themed('spaceBg') !important;
    }
    border-radius: 16px;
    height: 36px;
  }
}
.refresh-icon {
  position: relative;
  height: 46px;
  width: 46px;
  // top: calc(100% - 0px);
  font-size: 20px;
  // left: calc(50% - 0px);
  z-index: 9999;
  // margin-left: 5px;
  border-radius: 23px;
  &:hover {
    filter: brightness(0.7);
  }
}
.refresh-icon-cover {
  height: 46px;
  width: 46px;
  top: calc(100% - 40px);
  font-size: 20px;
  left: calc(50% - 24px);
  position: absolute;
  // @include themify($themes) {
  //   background: themed('refreshIcon');
  // }
  border-radius: 23px;
  z-index: 9999;
}
.depo__swap-header {
  position: relative;
  z-index: 2;
}
.depo__text-input-swap {
  background: none;
  border: 0;
  outline: none;
  font-size: 24px;
  width: 100%;
  font-weight: 550;
  font-family: 'Gilroy-Medium';
  &.input-lg {
    font-size: 24px;
    height: 36px !important;
    font-weight: 550;
    &:focus-visible {
      outline: none !important;
    }
  }
  &.input-md {
    font-size: 24px;
    font-weight: 550;
    height: 20px;
    &:focus-visible {
      outline: none !important;
    }
  }
  &::placeholder {
    font-size: 24px !important;
    font-weight: 550;
    // color: #ffffff;
    @include themify($themes) {
      color: themed('textColor');
    }
  }
}
.btn-max {
  padding: 0 !important;
  // margin-left: 8px;
  background-color: transparent !important;
  // color: #007aff !important;
  @include themify($themes) {
    color: themed('textColor') !important;
  }
  font-weight: 500 !important;
}
.depo__swap-result-fees {
  &::before {
    content: '';
    position: absolute;
    left: 10%;
    top: 40%;
  }
}
.arc-bg-black {
  background-color: transparent !important;
}
.arc__swap-body-wrapper {
  max-width: 386px !important;
  background: none !important;
  .depo_fulfill-progress {
    transform: scale(0.2);
    width: 20px;
    height: 20px;
    .progress-bar {
      position: relative;
    }
  }
  .title {
    font-size: 26px;
    font-family: 'Gilroy-bold';
    font-weight: 600;
    line-height: 28px;
  }
  .title-select-balance {
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 28px;
  }
  .swap-detail-info {
    border: 1px solid #2c2c2c;
    font-weight: 500;
    margin-bottom: 48px;
    .detail-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include themify($themes) {
        color: themed('textColor') !important;
      }
      p {
        margin: 0;
        line-height: 3;
        @include themify($themes) {
          color: themed('textColorSwap') !important;
        }
        // &:first-child {
        //   color: #cdcdcd;
        // }
      }
    }
  }
}
//panel
.arc__swap-panel-info {
  border-radius: 0 0 15px 15px !important;
  margin-top: -40px;
  // margin-bottom: 6px;
  border: none !important;
  // background-color: transparent !important;
  @include themify($themes) {
    background-color: themed('transactionInfoBg') !important;
  }
  z-index: 10000;
  .swap-detail-info {
    // border: 1px solid #2c2c2c;
    font-weight: 500;
    // margin-bottom: 48px;
    .detail-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // color: #8d8d8d;
      @include themify($themes) {
        color: themed('textColor') !important;
      }
      p {
        margin: 0;
        line-height: 3;
        &:first-child {
          // color: #cdcdcd;
          @include themify($themes) {
            color: themed('textColorSwap') !important;
          }
        }
      }
    }
  }
  .ant-collapse-header {
    padding-top: 8px !important;
    padding-bottom: 10px !important;
    padding-right: 32px !important;
  }
  .ant-collapse-content {
    margin-top: -3px;
  }
  .ant-collapse-content-box {
    padding-top: 0px !important;
  }
  .panel-header-price {
    text-align: left;
  }
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  @include themify($themes) {
    color: themed('textColor') !important;
  }
}
.ant-collapse > .ant-collapse-item {
  border-bottom: none !important;
}
.ant-collapse-content {
  // color: rgba(0, 0, 0, 0.85);
  background-color: transparent !important;
  @include themify($themes) {
    // background-color: themed('panelBackground') !important;
    color: themed('textColor') !important;
  }
  border-top: none !important;
  border-radius: 0px 0px 20px 20px !important;
}
// end of panel
.depo__swap-body-wrapper {
  max-width: 513px;
  // background: #000000 !important;
  @include themify($themes) {
    background: themed('bgModalBackground') !important;
  }
  // border: 2px solid rgba(68, 68, 73, 1);
  border-radius: 28px;
  padding: 0px 3px 0px;
  .depo_fulfill-progress {
    transform: scale(0.2);
    width: 20px;
    height: 20px;
    .progress-bar {
      position: relative;
    }
  }
  .swap-detail-info {
    // border: 1px solid #2c2c2c;
    font-weight: 500;
    // margin-bottom: 48px;
    .detail-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #8d8d8d;
      p {
        margin: 0;
        line-height: 2;
        &:first-child {
          color: #cdcdcd;
        }
      }
    }
  }
}
.depo__swap-mode-selector-wrapper {
  .swap-mode-selector {
    display: flex;
    gap: 32px;
    display: flex;
    justify-content: left;
    padding-top: 16px;
    div {
      cursor: pointer;
      @include themify($themes) {
        color: themed('swapModeSelectorColor') !important;
      }
      font-family: Gilroy-Medium;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-bottom: 5px;
    }
    .active {
      opacity: 1;
      background-color: transparent;
      border-width: 0px 0px 2px;
      border-bottom-style: solid;
      border-bottom-color: transparent;
      background-image: linear-gradient(
        to right,
        rgb(8, 26, 50),
        rgb(1, 123, 234)
      );
      background-size: calc(100% - 1px) 3px;
      background-repeat: no-repeat;
      background-origin: border-box;
      background-position: left bottom;
      box-sizing: border-box;
      @include themify($themes) {
        color: themed('swapModeSelectorActiveColor') !important;
      }
    }
  }
}
.depo__swap_cross-chain-swap-body {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .cross-chain-swap-from-token {
    padding: 16px 24px !important;
    border-radius: 20px;
    @include themify($themes) {
      background: themed('crossChainFromTokenBg') !important;
    }
    .depo__swap-body-from-token {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .refresh-icon-cover {
      left: calc(50% - 16px) !important;
      top: calc(100% + 6px) !important;
      .refresh-icon {
        width: 32px;
        height: 32px;
        border-radius: 10px;
        border: 1px solid #007aeb;
        @include themify($themes) {
          background: themed('crossChainRefreshBg') !important;
        }
      }
    }
    .token-amount-input-line-top {
      height: 24px;
    }
    .token-amount-input-line-bottom {
      height: 18px;
      padding-bottom: 0 !important;
    }
  }
  .cross-chain-swap-to-token {
    padding: 16px 24px !important;
    border-radius: 20px;
    @include themify($themes) {
      background: themed('crossChainFromTokenBg') !important;
    }
    .depo__swap-body-to-token {
      padding: 0 !important;
      background: transparent;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .token-amount-input-line-top {
        height: 24px;
      }
      .token-amount-input-line-bottom {
        height: 18px;
        margin-top: 0px;
      }
    }
  }
}
.depo__swap-body-to-token {
  position: relative;
  padding: 1.5rem !important;
  background: url(../../assets/subtract.svg) 0% 0% / cover;
}
.depo__swap-body-to-token-bg-light {
  background: url(../../assets/subtractLight.svg) 0% 0% / cover;
}
.depo__swap_limit-order-swap-body {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .limit-order-swap-from-token {
    padding: 16px 24px !important;
    border-radius: 20px;
    @include themify($themes) {
      background: themed('crossChainFromTokenBg') !important;
    }
    .depo__swap-body-from-token {
      height: 88px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // gap: 18px;
    }
    .refresh-icon-cover {
      left: calc(50% - 16px) !important;
      top: calc(100% + 6px) !important;
      .refresh-icon {
        width: 32px;
        height: 32px;
        border-radius: 10px;
        border: 1px solid #007aeb;
        @include themify($themes) {
          background: themed('crossChainRefreshBg') !important;
        }
      }
    }
    .token-amount-input-line-top {
      height: 24px;
    }
    .token-amount-input-line-bottom {
      height: 18px;
      padding-bottom: 0 !important;
    }
  }
  .limit-order-swap-to-token {
    padding: 16px 24px !important;
    border-radius: 20px;
    @include themify($themes) {
      background: themed('crossChainFromTokenBg') !important;
    }
    .depo__swap-body-to-token {
      padding: 0 !important;
      background: transparent;
      min-height: 88px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // gap: 18px;
      .token-amount-input-line-top {
        height: 24px;
      }
      .token-amount-input-line-bottom {
        height: 18px;
        margin-top: 0px;
      }
    }
  }
  .limit-order-swap-detail {
    height: 120px;
    padding: 16px 24px;
    border-radius: 20px;
    @include themify($themes) {
      background: themed('crossChainFromTokenBg') !important;
    }
    .header {
      @include themify($themes) {
        color: themed('limitOrderTxColor') !important;
      }
      text-align: left;
    }
    .body {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-top: 4px;
    }
  }
}
.expire-time-dropdown {
  .ant-dropdown-menu {
    background: #0b1e3c;
  }
}
.expire-time-dropdown-light {
  .ant-dropdown-menu {
    background: #747679;
  }
}
.network-dropdown {
  .ant-dropdown-menu {
    background: #0b1e3c;
  }
}
.network-dropdown-light {
  .ant-dropdown-menu {
    background: #dfdfdf;
  }
}
.depo__settings-icon {
  z-index: 2;
  > div {
    // right: -15px;
    top: -50px;
  }
}
.depo__price-select-tab {
  .title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    line-height: 30px;
    margin-bottom: 32px;
  }
  .depo__price-select {
    .depo__price-option {
      border: 2px solid #252525;
      padding: 16px;
      font-weight: 500;
      margin: 24px 0;
      &.selected {
        border: 2px solid #007aff;
      }
      &:hover {
        cursor: pointer;
        border: 2px solid #007aff;
      }
      .detail-item {
        display: flex;
        justify-content: space-between;
        color: #fff;
        p {
          margin-bottom: 8px;
          &:last-child {
            color: #8d8d8d;
          }
        }
      }
    }
  }
  .rounded-sm {
    border-radius: 7px !important;
  }
}
.depo__swap-confirm-tab {
  .title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    line-height: 30px;
    margin-top: 32px;
  }
  .left-arrow {
    cursor: pointer;
    float: left;
    background-color: transparent !important;
  }
  .down-arrow {
    text-align: center;
    img {
      padding: 5px;
      border-radius: 5px;
      background-color: #007aff;
      margin-top: -24px;
    }
  }
  .token-detail-info {
    display: flex;
    justify-content: space-between;
    border: 1px solid #2c2c2c;
    border-radius: 10px;
    padding: 16px;
    font-size: 14px;
    .token {
      span {
        margin-left: 10px;
        font-weight: 500;
      }
      img {
        width: 24px;
        height: 24px;
        border: 1px solid transparent;
        border-radius: '100%';
      }
    }
    .token-amount {
      font-weight: 550;
      margin: 0;
    }
  }
  .swap-detail-info {
    margin: 16px 0 12px;
    .detail-item {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 500;
      p:first-child {
        color: #cdcdcd;
      }
      .btn-edit-slippage {
        font-size: 12px;
        font-weight: 700;
        color: #007aff !important;
        background-color: transparent !important;
      }
    }
  }
  .rounded-sm {
    border-radius: 7px !important;
  }
}
.depo__fulfill-modal {
  min-width: 468px;
  position: relative;
  border: 0.5px solid #cdcdcd;
  .swap_modal {
    text-align: center;
    padding: 10px;
  }
  .swap_title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }
  .swap_info {
    font-size: 18px;
    font-weight: 500;
  }
  .swap_detail {
    font-size: 14px;
  }
  .btn_swap_error {
    border-radius: 20px;
  }
  .depo_fulfill-progress {
    top: -17px;
    left: -135px;
  }
  .close {
    position: absolute;
    top: 0.5em;
    left: calc(100% - 1.5em);
    font-size: 2em;
    z-index: 2;
    opacity: 0.7;
    transition: 200ms;
    &:hover {
      opacity: 0.9;
      transform: scale(1.5);
    }
  }
  .btn-close {
    float: right;
    background-color: transparent !important;
    position: absolute;
    right: 0;
    top: 6px;
  }
  @media screen and (max-width: 400px) {
    min-width: 360px;
    width: 360px;
  }
}
.depo__fulfill-modal_1 {
  // min-width: 468px;
  position: relative;
  border: 0.5px solid #cdcdcd;
  .swap_modal {
    text-align: center;
    padding: 10px;
  }
  .swap_title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }
  .swap_info {
    font-size: 18px;
    font-weight: 500;
  }
  .swap_detail {
    font-size: 14px;
  }
  .btn_swap_error {
    border-radius: 20px;
  }
  .depo_fulfill-progress {
    top: -17px;
    left: -135px;
  }
  .close {
    position: absolute;
    top: 0.5em;
    left: calc(100% - 1.5em);
    font-size: 2em;
    z-index: 2;
    opacity: 0.7;
    transition: 200ms;
    &:hover {
      opacity: 0.9;
      transform: scale(1.5);
    }
  }
  .btn-close {
    float: right;
    background-color: transparent !important;
    position: absolute;
    right: 0;
    top: 6px;
  }
  @media screen and (max-width: 400px) {
    // min-width: 360px;
    // width: 360px;
  }
}
.depo_fulfill-progress {
  .ant-progress-inner {
    height: 90px !important;
    width: 90px !important;
  }
  transform: scale(0.3);
  position: relative;
  top: 10px;
  right: 10px;
  .elapsed-time {
    font-size: 40px;
    // font-family: monospace;
    font-family: 'Gilroy-Light' !important;
    margin-left: -3px;
    margin-top: 5px;
  }
  .elapsed-time,
  .progress-bar {
    border-radius: 100px;
    position: absolute;
    .ant-progress-circle-trail {
      stroke: transparent !important;
    }
    .ant-progress-inner:not(.ant-progress-circle-gradient)
      .ant-progress-circle-path {
      stroke: var(--brand-swap-success);
    }
  }
}
.depo__collapse-pane {
  background: transparent !important;
  color: white !important;
  border: none !important;
  padding: 0 !important;
  * {
    padding: 0 0 0 3px !important;
    background: transparent !important;
    border: none !important;
    color: white !important;
  }
  .ant-collapse-content {
    margin-top: 10px;
    margin-left: 1;
  }
}
.slippage-selector {
  cursor: pointer;
  height: 50px;
  min-width: 50px;
  margin: 0 5px;
  // border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--brand-dark-b-alpha);
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.2);
  &:hover,
  &.selected {
    background-color: var(--brand-dark-b-hover-alpha);
  }
  &.selected {
    border-color: var(--brand-success);
  }
  border-radius: 8px;
  * {
    color: white !important;
  }
}
.slippage-group {
  flex-wrap: wrap;
  .ant-radio-button-wrapper {
    margin-bottom: 4px;
    background: red;
  }
}
.depo__token-select-tab {
  .depo__token-select-mainpanel {
    top: 0;
    z-index: 9;
    user-select: none;
    .depo__token-list {
      height: 400px;
      padding-bottom: 40px;
      overflow-y: auto;
      .depo__token-list-item {
        cursor: pointer;
        .depo__token-namebar {
          display: flex;
          width: 100%;
          justify-content: space-between;
          border-bottom: 1px solid #2c2c2c;
        }
        &:hover {
          background-color: rgba(223, 223, 228, 0.9019607843);
        }
      }
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        @include themify($themes) {
          background-color: themed('scrollbarTrack');
        }
        // background-color: #2c2c2c;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        // box-shadow: inset 0 0 100px rgba(18, 18, 18);
        @include themify($themes) {
          box-shadow: themed('scrollbarThumb');
        }
        border-radius: 5px;
      }
    }
    .title {
      font-size: 20px;
      font-weight: 700;
      text-align: left;
      line-height: 30px;
      margin-bottom: 32px;
    }
    .depo__token-search-light {
      input {
        color: rgb(2, 2, 2);
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        background-color: transparent;
        border: 1px solid #2d2d31;
        border-radius: 28px;
      }
      // background-color: #2c2c2c;
      border-radius: 28px;
      margin-bottom: 26px;
    }
    .depo__token-search-black {
      input {
        color: white;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        background-color: transparent;
        border: 1px solid #2d2d31;
        border-radius: 28px;
      }
    }
    .depo__token-search-out {
      border-radius: 28px !important;
      margin-bottom: 26px !important;
    }
    .depo__token-search {
      input {
        color: white;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 18px;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        background-color: #0B1E3C;
        border: 1px solid #2d2d31;
        border-radius: 28px;
      }
    }
    .depo__token-static-list {
      margin-bottom: 16px;
    }
    .depo__token-to-mng-list {
      // position: absolute;
      // bottom: -25px;
      width: 100%;
      // border-bottom-left-radius: 10px;
      // border-bottom-right-radius: 10px;
      background-color: #17176d;
      border-radius: 28px;
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      .toMngList {
        background-color: transparent !important;
        // font-family: Montserrat;
        font-family: 'Gilroy-Light' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
    }
  }
  .depo__token-select-mainpanel-light {
    top: 0;
    z-index: 9;
    user-select: none;
    .depo__import-token-button {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .depo__token-list {
      height: 400px;
      padding-bottom: 40px;
      overflow-y: auto;
      .depo__token-list-item {
        cursor: pointer;
        .depo__token-namebar {
          display: flex;
          width: 100%;
          justify-content: space-between;
          // border-bottom: 1px solid #2c2c2c;
        }
        &:hover {
          background-color: rgba(223, 223, 228, 0.9019607843);
        }
      }
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background-color: rgba(229, 242, 255, 1);
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 100px rgb(213 212 212);
        border-radius: 5px;
      }
    }
    .title {
      font-size: 20px;
      font-weight: 700;
      text-align: left;
      line-height: 30px;
      margin-bottom: 20px;
    }
    .depo__token-search {
      input {
        color: white;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 18px;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        background-color: #0b1e3c;
        border: 1px solid #2d2d31;
        border-radius: 28px;
      }
      // background-color: #2c2c2c;
      border-radius: 28px;
      margin-bottom: 20px;
    }
    .depo__token-static-list {
      margin-bottom: 16px;
    }
    .depo__token-to-mng-list {
      // position: absolute;
      // bottom: -25px;
      width: 98%;
      margin-bottom: 5px;
      // border-bottom-left-radius: 10px;
      // border-bottom-right-radius: 10px;
      background-color: #2d2d31;
      border-radius: 28px;
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      .toMngList {
        background-color: transparent !important;
        // font-family: Montserrat;
        font-family: 'Gilroy-Light' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
    }
  }
  .depo__token-select-mainpanel-dark {
    top: 0;
    z-index: 9;
    user-select: none;
    .depo__import-token-button {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .depo__token-list {
      height: 400px;
      padding-bottom: 40px;
      overflow-y: auto;
      .depo__token-list-item {
        cursor: pointer;
        .depo__token-namebar {
          display: flex;
          width: 100%;
          justify-content: space-between;
          // border-bottom: 1px solid #2c2c2c;
        }
        &:hover {
          background-color: rgb(53 53 54 / 90%);
        }
      }
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background-color: #2c2c2c;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 100px rgba(18, 18, 18);
        border-radius: 5px;
      }
    }
    .title {
      font-size: 20px;
      font-weight: 700;
      text-align: left;
      line-height: 30px;
      margin-bottom: 20px;
    }
    .depo__token-search-light {
      input {
        color: rgb(2, 2, 2);
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 18px;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        background-color: transparent;
        border: 1px solid #2d2d31;
        border-radius: 28px;
      }
    }
    .depo__token-search-black {
      input {
        color: white;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 18px;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        background-color: transparent;
        border: 1px solid #2d2d31;
        border-radius: 28px;
      }
    }
    .depo__token-search {
      input {
        color: white;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 18px;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        background-color: #0b1e3c;
        border: 1px solid #2d2d31;
        border-radius: 28px;
      }
      // background-color: #2c2c2c;
      border-radius: 28px;
      margin-bottom: 8 px;
    }
    .depo__token-static-list {
      margin-bottom: 16px;
    }
    .depo__token-to-mng-list {
      // position: absolute;
      // bottom: -25px;
      width: 98%;
      margin-bottom: 5px;
      // border-bottom-left-radius: 10px;
      // border-bottom-right-radius: 10px;
      background-color: #2d2d31;
      border-radius: 28px;
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      .toMngList {
        background-color: transparent !important;
        // font-family: Montserrat;
        font-family: 'Gilroy-Light' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
    }
  }
}
.depo__token-mng-tab {
  .title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    line-height: 30px;
    margin-bottom: 32px;
  }
  .left-arrow {
    cursor: pointer;
    float: left;
    background-color: transparent !important;
  }
  .depo__token-mng-mainpanel {
    height: 638px;
    .depo__token-mng-input,
    .depo__token-import-input {
      input {
        color: white;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        background-color: transparent;
        border: 1px solid #2d2d31 !important;
        border-radius: 28px;
      }
      // background-color: #2c2c2c;
      border-radius: 28px;
      margin-bottom: 26px;
    }
    .depo__token-list {
      padding-bottom: 18px;
      overflow-y: auto;
      border-bottom: 1px solid #2c2c2c;
      .depo__token-list-item {
        .depo__token-namebar {
          display: flex;
          justify-content: flex-start;
        }
      }
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background-color: #2c2c2c;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 100px rgba(18, 18, 18);
        border-radius: 5px;
      }
    }
    .depo__token-imported-list {
      padding-top: 24px;
      padding-left: 4px;
      padding-right: 4px;
      &-header {
        display: flex;
        justify-content: space-between;
        .title {
          margin: 0 !important;
          align-self: center !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0em;
        }
        .clearBtn {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0em;
          background-color: transparent !important;
        }
      }
      .depo__token-IMlist {
        padding-top: 24px;
        overflow-y: auto;
        .depo__token-list-item {
          .depo__token-namebar {
            display: flex;
            justify-content: flex-start;
          }
          .depo__token-imported-token-buttons {
            button {
              background-color: transparent !important;
            }
          }
        }
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-track {
          background-color: #2c2c2c;
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
          box-shadow: inset 0 0 100px rgba(18, 18, 18);
          border-radius: 5px;
        }
      }
    }
  }
  .depo__token-mng-mainpanel-dark {
    height: 638px;
    .depo__token-mng-input,
    .depo__token-import-input {
      input {
        color: white;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        background-color: transparent;
        border: 1px solid #2d2d31 !important;
        border-radius: 28px;
      }
      // background-color: #2c2c2c;
      border-radius: 28px;
      margin-bottom: 26px;
    }
    .depo__token-list {
      padding-bottom: 18px;
      overflow-y: auto;
      border-bottom: 1px solid #2c2c2c;
      .depo__token-list-item {
        .depo__token-namebar {
          display: flex;
          justify-content: flex-start;
        }
      }
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background-color: #2c2c2c;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 100px rgba(18, 18, 18);
        border-radius: 5px;
      }
    }
    .depo__token-imported-list {
      padding-top: 24px;
      padding-left: 4px;
      padding-right: 4px;
      &-header {
        display: flex;
        justify-content: space-between;
        .title {
          margin: 0 !important;
          align-self: center !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0em;
        }
        .clearBtn {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0em;
          background-color: transparent !important;
        }
      }
      .depo__token-IMlist {
        padding-top: 24px;
        overflow-y: auto;
        .depo__token-list-item {
          .depo__token-namebar {
            display: flex;
            justify-content: flex-start;
          }
          .depo__token-imported-token-buttons {
            button {
              background-color: transparent !important;
            }
          }
        }
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-track {
          background-color: #2c2c2c;
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
          box-shadow: inset 0 0 100px rgba(18, 18, 18);
          border-radius: 5px;
        }
      }
    }
  }
  .depo__token-mng-mainpanel-light {
    height: 638px;
    .depo__token-mng-input,
    .depo__token-import-input {
      input {
        color: rgb(2, 2, 2);
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        background-color: transparent;
        border: 1px solid #2d2d31;
        border-radius: 28px;
      }
      // background-color: #2c2c2c;
      // border-radius: 10px;
      // margin-bottom: 26px;
    }
    .depo__token-list {
      padding-bottom: 18px;
      overflow-y: auto;
      border-bottom: 1px solid #2c2c2c;
      .depo__token-list-item {
        .depo__token-namebar {
          display: flex;
          justify-content: flex-start;
        }
      }
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background-color: rgba(229, 242, 255, 1);
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 100px rgb(213 212 212);
        border-radius: 5px;
      }
    }
    .depo__token-imported-list {
      padding-top: 24px;
      padding-left: 4px;
      padding-right: 4px;
      &-header {
        display: flex;
        justify-content: space-between;
        .title {
          margin: 0 !important;
          align-self: center !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0em;
        }
        .clearBtn {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0em;
          background-color: transparent !important;
        }
      }
      .depo__token-IMlist {
        padding-top: 24px;
        overflow-y: auto;
        .depo__token-list-item {
          .depo__token-namebar {
            display: flex;
            justify-content: flex-start;
          }
          .depo__token-imported-token-buttons {
            button {
              background-color: transparent !important;
            }
          }
        }
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-track {
          background-color: rgba(229, 242, 255, 1);
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
          box-shadow: inset 0 0 100px rgb(213 212 212);
          border-radius: 5px;
        }
      }
    }
  }
  .footer-tooltip {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #8d8d8d;
  }
}
.depo__token-import-tab {
  .title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    line-height: 30px;
    margin-bottom: 32px;
  }
  .left-arrow {
    cursor: pointer;
    float: left;
    background-color: transparent !important;
  }
  .depo__token-import-mainpanel {
    position: relative;
    height: 638px;
    .depo__token-namebar {
      padding-top: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid #2c2c2c;
      display: flex;
      justify-content: flex-start;
      .depo__token-title {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
      }
    }
    .depo__token-import-tooltip {
      margin-top: 24px;
      background-color: #feefef;
      border: 1px solid #2c2c2c;
      border-radius: 10px;
      display: flex;
      img {
        margin-right: 19px;
        width: 19px;
        height: 19px;
      }
      .infoRightTooltip {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #a40b0b;
      }
    }
    .depo__token-import-button {
      button {
        position: absolute;
        bottom: 20px;
      }
    }
  }
  .depo__token-import-mainpanel-light {
    position: relative;
    height: 638px;
    .depo__token-namebar {
      padding-top: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid #2c2c2c;
      display: flex;
      justify-content: flex-start;
      .depo__token-title {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
      }
    }
    .depo__token-import-tooltip {
      margin-top: 24px;
      background-color: #feefef;
      border: 1px solid #2c2c2c;
      border-radius: 10px;
      display: flex;
      img {
        margin-right: 19px;
        width: 19px;
        height: 19px;
      }
      .infoRightTooltip {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #a40b0b;
      }
    }
    .depo__token-import-button {
      button {
        position: absolute;
        bottom: 20px;
      }
    }
  }
  .depo__token-import-mainpanel-dark {
    position: relative;
    height: 638px;
    .depo__token-namebar {
      padding-top: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid #2c2c2c;
      display: flex;
      justify-content: flex-start;
      .depo__token-title {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
      }
    }
    .depo__token-import-tooltip {
      margin-top: 24px;
      background-color: #feefef;
      border: 1px solid #2c2c2c;
      border-radius: 10px;
      display: flex;
      img {
        margin-right: 19px;
        width: 19px;
        height: 19px;
      }
      .infoRightTooltip {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #a40b0b;
      }
    }
    .depo__token-import-button {
      button {
        position: absolute;
        bottom: 20px;
      }
    }
  }
}
.depo__token-import-confirm {
  .depo__token-import-success {
    text-align: center;
    div {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      width: 80%;
      // padding-bottom: 56px;
      margin: auto;
    }
    button {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      margin-bottom: 16px;
      img {
        margin-left: 12px;
      }
    }
  }
}
#depo__swap {
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-iteration-count: infinite;

  .ant-input {
    background-color: #2c2c2c;
    border-radius: 10px;
    color: #fff;
    font-size: 22px;
    border-width: 0px;
  }
  .ant-input::placeholder {
    // font-family: Montserrat;
    font-family: 'Gilroy-Light' !important;
    font-style: normal;
    font-size: 18px !important;
    line-height: 22px !important;
    color: #ffffff !important;
  }
  .ant-input.warning {
    border: 1px solid rgb(255, 67, 67);
  }
}
#selectTokenModal {
  .depo__token-search {
    .ant-input-affix-wrapper {
      background-color: transparent !important;
      border: none !important;
    }
  }
  .depo__token-mng-input,
  .depo__token-import-input {
    .ant-input {
      background-color: transparent !important;
      // border: none !important;
    }
  }
  .ant-tabs-top > .ant-tabs-nav {
    &::before {
      border: 1px solid #2c2c2c;
    }
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    flex: 0 0 90% !important;
    .ant-tabs-tab {
      flex: 0 0 50% !important;
      justify-content: center !important;
      margin: 0 !important;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #007aff !important;
  }
  .ant-tabs-tab-btn {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    color: #8d8d8d !important;
  }
  .ant-tabs-ink-bar {
    background-color: #007aff;
  }
  .ant-tabs-content-holder {
    padding: 8px 0 24px 0 !important;
    color: white !important;
  }
}
.bg-darkmodal {
  @include themify($themes) {
    background: themed('bgModalBackground') !important;
    color: themed('textColorBlue') !important;
  }
}
.bg-darkmodal-settings {
  @include themify($themes) {
    background: themed('bgModalBackgroundSettings') !important;
    color: themed('textColorBlue') !important;
  }
}
.rounded-modal {
  border-radius: 28px;
  border: 1px solid rgba(45, 45, 49, 1);
}
.anon-receiver-address-container {
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  .anon-receiver-title {
    flex: 0 0 100%;
    font-size: 12px;
    @include themify($themes) {
      color: themed('textColor') !important;
    }
    width: 100%;
  }
  .anon-receiver-input {
    flex: 0 0 100%;
    .ant-input {
      @include themify($themes) {
        background-color: themed('bodyBackgroundColor') !important;
        border: 1px solid themed('textColor') !important;
        color: themed('textColor') !important;
      }
      background-color: #081325 !important;
      border-radius: 20px !important;
      border: 1px solid white !important;
      font-size: 18px !important;
      // border-width: 0px;
    }
    .ant-input::placeholder {
      // font-family: Montserrat;
      font-family: 'Gilroy-Light' !important;
      font-style: normal;
      font-size: 18px !important;
      line-height: 22px !important;
      @include themify($themes) {
        color: themed('textColor') !important;
      }
    }
    .ant-input.warning {
      border: 1px solid rgb(255, 67, 67);
    }
  }
}
.swap-setting-wrapper {
  display: flex;
  flex-direction: column;
  z-index: 99999;
  @include themify($themes) {
    background: themed('swapSettings') !important;
  }
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 265px;
  position: absolute;
  top: 0;
  margin-left: 16px;
  left: 100%;
  border-radius: 10px;
  padding: 15px;
  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #ffffff;
    @include themify($themes) {
      color: themed('textColor') !important;
    }
    margin-bottom: 10px;
  }
  .subtitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    @include themify($themes) {
      color: themed('textColor') !important;
    }
    margin-bottom: 6px;
  }
  .setting-opt {
    padding: 15px;
    border-radius: 16px;
  }
  .houdini-wrapper {
    margin-top: 5px;
  }
  .ant-radio-group {
    background-color: transparent !important;
    height: auto !important;
    border-radius: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    // justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px 30px;
    .ant-radio-button-wrapper {
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      align-items: center !important;
      @include themify($themes) {
        background-color: themed('swapOptionBack') !important;
        color: themed('textColor') !important;
      }
      border: 2px solid #007aff;
      border-radius: 23px !important;
      font-size: 13px !important;
      font-weight: 400 !important;
      padding: 2px 4px !important;
      min-width: 47px !important;
      line-height: 24px !important;
      &.ant-radio-button-wrapper-checked {
        color: #ffffff !important;
        // @include themify($themes) {
        //   color: themed('lightColor') !important;
        // }
        background: #007aff !important;
        font-weight: 550 !important;
        height: auto !important;
      }
      &:before {
        display: none !important;
      }
    }
  }
}
.swap_anonymous_wrapper {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}
.select-token-drawer {
  .ant-drawer-content-wrapper {
    // height: calc(100vh - 100px) !important;
    height: auto !important;
    padding: 0px;
    box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08),
      0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
  }
  .ant-drawer-content {
    border-radius: 40px 40px 0px 0px;
  }
  .ant-drawer-header {
    border-bottom: 0px;
    padding: 32px 16px 0px 16px !important;
  }
  .ant-drawer-body {
    padding: 16px !important;
  }
  .ant-drawer-title {
    font-family: Gilroy-Semibold;
    font-size: 20px;
  }
  .ant-space-item {
    button {
      // padding-right: 0px;
    }
  }
  .depo__token-list-item {
    height: 68px;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .depo__token-list-item:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  &.dark {
    .ant-drawer-content {
      background: #0b1e3c !important;
    }
    .ant-drawer-content {
      // border: 1px solid #fff;
      border-bottom: 0px;
    }
    .ant-drawer-close {
      color: #fff;
    }
    .ant-drawer-title {
      color: #fff;
    }
    .depo__token-search {
      position: fixed;
      display: contents;
      input {
        color: white;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        background-color: #031531;
        // border: 1px solid #b6b6bb;
        border: none;
        border-radius: 28px;
        margin-bottom: 6px;
      }
      // background-color: #2c2c2c;
      border-radius: 28px;
      margin-bottom: 10px;
    }
    .depo__token-list {
      // height: 350px;
      margin-top: 10px;
      padding-bottom: 40px;
      overflow: auto;
      .depo__token-list-item {
        cursor: pointer;
        .depo__token-namebar {
          display: flex;
          width: 100%;
          justify-content: space-between;
          color: #b6b6bb;
          // border-bottom: 1px solid #2c2c2c;
        }
        &:hover {
          background-color: rgb(53 53 54 / 90%);
        }
      }
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background-color: #2c2c2c;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 100px rgba(18, 18, 18);
        border-radius: 5px;
      }
    }
    .drawer__token-mng-tab {
      .title {
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        line-height: 30px;
        margin-bottom: 32px;
      }
      .left-arrow {
        cursor: pointer;
        float: left;
        background-color: transparent !important;
      }
      .drawer__token-mng-mainpanel-dark {
        .depo__token-mng-input,
        .depo__token-import-input {
          input {
            color: white;
            padding-top: 15px;
            padding-bottom: 15px;
            padding-left: 18px;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0em;
            background-color: #031531;
            // border: 1px solid #2d2d31 !important;
            border: none;
            border-radius: 28px;
          }
          border-radius: 28px;
          margin-bottom: 26px;
        }
        .depo__token-list {
          padding-bottom: 18px;
          overflow-y: auto;
          border-bottom: 1px solid #2c2c2c;
          .depo__token-list-item {
            .depo__token-namebar {
              display: flex;
              justify-content: flex-start;
            }
          }
          &::-webkit-scrollbar {
            width: 10px;
          }
          &::-webkit-scrollbar-track {
            background-color: #2c2c2c;
            border-radius: 5px;
          }
          &::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 100px rgba(18, 18, 18);
            border-radius: 5px;
          }
        }
        .depo__token-imported-list {
          padding-top: 24px;
          padding-left: 4px;
          padding-right: 4px;
          &-header {
            display: flex;
            justify-content: space-between;
            .title {
              margin: 0 !important;
              align-self: center !important;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 22px;
              letter-spacing: 0em;
            }
            .clearBtn {
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 22px;
              letter-spacing: 0em;
              background-color: transparent !important;
            }
          }
          .depo__token-IMlist {
            padding-top: 24px;
            overflow-y: auto;
            .depo__token-list-item {
              color: #fff;
              .depo__token-namebar {
                display: flex;
                justify-content: flex-start;
              }
              .depo__token-imported-token-buttons {
                button {
                  background-color: transparent !important;
                }
              }
            }
            &::-webkit-scrollbar {
              width: 10px;
            }
            &::-webkit-scrollbar-track {
              background-color: #2c2c2c;
              border-radius: 5px;
            }
            &::-webkit-scrollbar-thumb {
              box-shadow: inset 0 0 100px rgba(18, 18, 18);
              border-radius: 5px;
            }
          }
        }
      }
      .footer-tooltip {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        color: #8d8d8d;
      }
    }
    .drawer__token-import-tab {
      // box-shadow: rgba(255, 255, 255, 0.2) 0px 8px 24px;
      // border-radius: 20px;
      .title {
        font-size: 20px;
        font-weight: 359;
        text-align: center;
        line-height: 30px;
        color: #ffffff;
      }
      .left-arrow {
        position: absolute;
        top: 32px;
        left: 0px;
        cursor: pointer;
        // float: left;
        background-color: transparent !important;
      }
      .depo__token-import-mainpanel-dark {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        height: 100%;
        .depo__token-image {
          display: flex;
        }
        .depo__token-namebar {
          width: 100%;
          display: flex;
          padding-top: 24px;
          padding-bottom: 24px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          color: #fff;
          .depo__token-title {
            display: flex;
          }
        }
        .depo__token-import-tooltip {
          margin-top: 24px;
          background-color: #feefef;
          border: 1px solid #2c2c2c;
          border-radius: 10px;
          display: flex;
          padding: 24px;
          img {
            margin-right: 19px;
            width: 19px;
            height: 19px;
          }
          .infoRightTooltip {
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            color: #a40b0b;
          }
        }
      }
    }
  }
  &.light {
    .ant-drawer-content {
      background: #fff !important;
    }
    .ant-drawer-content {
      // border: 1px solid #000;
      border-bottom: 0px;
    }
    .ant-drawer-close {
      color: #000;
    }
    .ant-drawer-title {
      color: #000;
    }
    .depo__token-search {
      input {
        color: rgb(2, 2, 2);
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        background-color: rgb(0, 0, 0, 0.1);
        // border: 1px solid #b6b6bb;
        border: none;
        border-radius: 28px;
        margin-bottom: 6px;
      }
      // background-color: #2c2c2c;
      border-radius: 28px;
      margin-bottom: 10px;
    }
    .depo__token-list {
      // height: 350px;
      margin-top: 10px;
      padding-bottom: 40px;
      overflow: auto;
      .depo__token-list-item {
        cursor: pointer;
        .depo__token-namebar {
          display: flex;
          width: 100%;
          justify-content: space-between;
          color: #000;
          // border-bottom: 1px solid #2c2c2c;
        }
        &:hover {
          background-color: rgb(53 53 54 / 90%);
        }
      }
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background-color: #eceaea;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 100px rgba(18, 18, 18);
        border-radius: 5px;
      }
    }
    .drawer__token-mng-tab {
      .title {
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        line-height: 30px;
        margin-bottom: 32px;
      }
      .left-arrow {
        cursor: pointer;
        float: left;
        background-color: transparent !important;
      }
      .drawer__token-mng-mainpanel-light {
        .depo__token-mng-input,
        .depo__token-import-input {
          input {
            color: black;
            padding-top: 15px;
            padding-bottom: 15px;
            padding-left: 18px;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0em;
            background-color: rgb(0, 0, 0, 0.1);
            // border: 1px solid #2d2d31 !important;
            border: none;
            border-radius: 28px;
          }
          border-radius: 28px;
          margin-bottom: 26px;
        }
        .depo__token-list {
          padding-bottom: 18px;
          overflow-y: auto;
          border-bottom: 1px solid #2c2c2c;
          .depo__token-list-item {
            .depo__token-namebar {
              display: flex;
              justify-content: flex-start;
            }
          }
          &::-webkit-scrollbar {
            width: 10px;
          }
          &::-webkit-scrollbar-track {
            background-color: #2c2c2c;
            border-radius: 5px;
          }
          &::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 100px rgba(18, 18, 18);
            border-radius: 5px;
          }
        }
        .depo__token-imported-list {
          padding-top: 24px;
          padding-left: 4px;
          padding-right: 4px;
          &-header {
            display: flex;
            justify-content: space-between;
            .title {
              margin: 0 !important;
              align-self: center !important;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 22px;
              letter-spacing: 0em;
            }
            .clearBtn {
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 22px;
              letter-spacing: 0em;
              background-color: transparent !important;
            }
          }
          .depo__token-IMlist {
            padding-top: 24px;
            overflow-y: auto;
            .depo__token-list-item {
              color: #000;
              .depo__token-namebar {
                display: flex;
                justify-content: flex-start;
              }
              .depo__token-imported-token-buttons {
                button {
                  background-color: transparent !important;
                }
              }
            }
            &::-webkit-scrollbar {
              width: 10px;
            }
            &::-webkit-scrollbar-track {
              background-color: #2c2c2c;
              border-radius: 5px;
            }
            &::-webkit-scrollbar-thumb {
              box-shadow: inset 0 0 100px rgba(18, 18, 18);
              border-radius: 5px;
            }
          }
        }
      }
      .footer-tooltip {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        color: #8d8d8d;
      }
    }
    .drawer__token-import-tab {
      // box-shadow: rgba(255, 255, 255, 0.2) 0px 8px 24px;
      // border-radius: 20px;
      .title {
        font-size: 20px;
        font-weight: 359;
        text-align: center;
        line-height: 30px;
        color: #000;
      }
      .left-arrow {
        position: absolute;
        top: 32px;
        left: 0px;
        cursor: pointer;
        // float: left;
        background-color: transparent !important;
      }
      .depo__token-import-mainpanel-light {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        height: 100%;
        .depo__token-image {
          display: flex;
        }
        .depo__token-namebar {
          width: 100%;
          display: flex;
          padding-top: 24px;
          padding-bottom: 24px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          color: #000;
          .depo__token-title {
            display: flex;
          }
        }
        .depo__token-import-tooltip {
          margin-top: 24px;
          background-color: #feefef;
          border: 1px solid #2c2c2c;
          border-radius: 10px;
          display: flex;
          padding: 24px;
          img {
            margin-right: 19px;
            width: 19px;
            height: 19px;
          }
          .infoRightTooltip {
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            color: #a40b0b;
          }
        }
      }
    }
  }
}
.ai-info-wrapper {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 10px;
  gap: 10px;
  position: absolute;
  width: 250px;
  left: 100%;
  margin-left: 20px;
  @include themify($themes) {
    background: themed('bgModalBackground') !important;
  }
  border: 2px solid rgb(68, 68, 73) !important;
  border-radius: 24px;
  @include themify($themes) {
    color: themed('textColor') !important;
  }
  .ai-title {
    width: 100%;
    @include themify($themes) {
      color: themed('textColor') !important;
    }
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
  }
  .ai-token-wrapper {
    padding: 0px 0px 0px 0px;
    width: 100%;
  }
  .ai-tokeninfo {
    box-sizing: border-box;
    /* Neutral/700
#444449
*/
    align-items: center;
    border: 2px solid #444449;
    border-radius: 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 50px;
    justify-content: space-between;
    padding: 6px 13px;
    width: 100%;
  }
  .ai-tokeninfo-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 88px;
  }
  .ai-experimental-subtext {
    font-size: 12px;
    font-weight: 300;
    color: #007aff;
    border: 1px solid #007aff;
    border-radius: 5px;
    padding: 5px;
  }
  .ai-tokeninfo-h {
    display: flex;
    /* justify-content: center !important; */
    /* text-align: center; */
    align-items: center;
    /* vertical-align: middle; */
    min-height: 40px;
  }
  .ai-tokeninfo-subtext {
    @include themify($themes) {
      color: themed('textColor') !important;
    }
    align-items: center;
    border: 2px solid #14ff00;
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    text-align: center;
    width: 40px;
  }
  .ai-tokeninfo-subtext-right {
    text-align: center;
    @include themify($themes) {
      color: themed('textColor') !important;
    }
    padding: 5px;
    border: 1px solid transparent; /*2*/
    margin-top: 3px;
    margin-bottom: 3px;
    // -webkit-mask: /*4*/
    //    linear-gradient(#fff 0 0) padding-box,
    //    linear-gradient(#fff 0 0);
    // -webkit-mask-composite: xor; /*5'*/
    //         mask-composite: exclude; /*5*/
  }
  .ai-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
  }
  .ai-tokenoverview-title {
    margin-bottom: 10px;
    margin-top: 5px;
    font-size: 24px;
    font-weight: 500;
    font-family: 'Gilroy-Bold' !important;
  }
  .ai-tokenoverview-title h1.ant-typography {
    @include themify($themes) {
      background: themed('textColor') !important;
    }
  }
  .ai-tokenoverview-sub {
    @include themify($themes) {
      color: themed('textColor') !important;
    }
    color: #ffffff !important;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Gilroy-Regular' !important;
    width: 80% !important;
  }
  .ai-link {
    background: #1890ff;
    font-size: 14px;
    width: 100%;
    height: 26px;
    border-radius: 24px;
    text-align: center;
  }
  .ai-link a {
    color: #fff;
    font-weight: bold;
  }
  .ai-tokenscan-item {
    width: 225px;
    display: flex;
    margin-top: 5px;
    justify-content: flex-start;
    white-space: nowrap;
    overflow: hidden;
    .ai-tokenscan-link {
      overflow: hidden;
      text-overflow: ellipsis;
      /* Adjust other link styles as desired */
      text-decoration: none;
      height: 35px;
      z-index: 9;
    }
  }

  .ai-tokenscan-item-modal {
    width: 225px;
    display: flex;
    margin-top: 5px;
    justify-content: flex-start;
    white-space: nowrap;
    overflow: hidden;

    .ai-tokenscan-link-modal {
      overflow: hidden;
      text-overflow: ellipsis;
      // color: #FFF;
      @include themify($themes) {
        color: themed('textColor') !important;
      }
      margin-left: 10px;
      font-size: 15px;
      /* Adjust other link styles as desired */
      text-decoration: none;
      z-index: 9;
    }
  }

  .ai-btn-close {
    position: absolute;
    right: -5px;
    margin-top: -8px;
  }
}
.animation-ctn {
  text-align: center;
  margin-top: 5em;
}
.swap-page-audit-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}
@-webkit-keyframes checkmark {
  0% {
    stroke-dashoffset: 100px;
  }
  100% {
    stroke-dashoffset: 200px;
  }
}
@-ms-keyframes checkmark {
  0% {
    stroke-dashoffset: 100px;
  }
  100% {
    stroke-dashoffset: 200px;
  }
}
@keyframes checkmark {
  0% {
    stroke-dashoffset: 100px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}
@-webkit-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 480px;
  }
  100% {
    stroke-dashoffset: 960px;
  }
}
@-ms-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px;
  }
  100% {
    stroke-dashoffset: 480px;
  }
}
@keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 480px;
  }
  100% {
    stroke-dashoffset: 960px;
  }
}
@keyframes colored-circle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}
/* other styles */
/* .svg svg {
    display: none
}
 */
.inlinesvg .svg svg {
  display: inline;
}
/* .svg img {
    display: none
} */
.icon--order-success svg polyline {
  -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
  animation: checkmark 0.25s ease-in-out 0.7s backwards;
}
.icon--order-success svg circle {
  -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
  animation: checkmark-circle 0.6s ease-in-out backwards;
}
.icon--order-success svg circle#colored {
  -webkit-animation: colored-circle 0.6s ease-in-out 0.7s backwards;
  animation: colored-circle 0.6s ease-in-out 0.7s backwards;
}
.animated-gradient {
  position: absolute;
  background: repeating-linear-gradient(to right, red 0%, blue 50%, red 100%);
  width: 217px;
  top: 50%;
  margin-top: -25px;
  height: 26px;
  background-size: 200% auto;
  background-position: 0 100%;
  animation: gradient 2s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  text-align: center;
  border-radius: 20px;
}
.mark-text {
  color: rgb(155, 151, 151);
}
mark {
  // color: #fff !important;

  @include themify($themes) {
    color: themed('textColor') !important;
  }
  background: none !important;
}
@keyframes gradient {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.rotate {
  animation: rotation 8s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media only screen and (max-width: 991px) {
  .swap-setting-wrapper {
    right: -16px;
    left: -16px;
    // border-radius: 0;
    top: 60px;
    transform: none;
    // width: calc(100% + 0px);
    left: 70px !important;
  }
  .swap-container-mobile {
    padding: 0;
    align-items: stretch;
    width: 454px;
  }
  .swap-module-container-mobile {
    border-radius: 34px;
    padding: 15px 33px;
  }
  .swap-page-audit-container {
    padding: 15px;
    .close {
      top: 30px !important;
      right: 30px !important;
    }
  }
}
@media only screen and (max-width: 768px) {
  .depo__fulfill-modal {
    min-width: 120px;
  }
  .modal_wrap {
    overflow-x: hidden !important;
  }
  .ai-btn-close {
    right: -0px !important;
  }
}
@media screen and (max-width: 576px) {
  .panel-header-price {
    width: 70%;
  }
  .panel-header-gas {
    margin: auto;
  }
  .swap-setting-wrapper {
    right: -16px;
    left: -16px;
    // border-radius: 0;
    top: 60px;
    transform: none;
    // width: calc(100% + 0px);
    left: 70px !important;
  }
  .ai-tokenscan-item {
    width: 180px !important;
  }
  .ai-info-wrapper {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 10px;
    gap: 10px;
    position: unset;
    width: 100%;
    min-height: 260px;
    left: 100%;
    top: 0px;
    margin-left: 0px;
    background: #000000;
    border: 1px solid #444449;
    border-radius: 24px;
    color: #ffffff;
  }
  .animated-gradient {
    width: 88% !important;
    top: 62%;
  }
}
@media screen and (max-width: 464px) {
  .swap-container-mobile {
    padding: 0;
    width: 100%;
  }
  .swap-module-container-mobile {
    border-radius: 30px;
    padding: 16px;
  }
}
.ant-dropdown-menu-item {
  color: inherit !important;
}

#chat-mode {
  animation-duration: 0.7s;
  display: none;
  // animation-name: scaleAnim;
}
#audit-mode {
  animation-duration: 0.7s;
  display: none;
  // animation-name: scaleAnim;
}

@keyframes scaleAnim {
  0% {
    transform: translateY(50%) scale(0, 0);
  }
  100% {
    transform: translateY(0%) scale(1, 1);
  }
}

@keyframes jiggle {
  0% {
    transform: translateX(0%);
  }
  10% {
    transform: translateX(2%);
  }
  20% {
    transform: translateX(-2%);
  }
  30% {
    transform: translateX(1.5%);
  }
  40% {
    transform: translateX(-1.5%);
  }
  50% {
    transform: translateX(1%);
  }
  60% {
    transform: translateX(-1%);
  }
  70% {
    transform: translateX(0.5%);
  }
  80% {
    transform: translateX(-0.5%);
  }
  90% {
    transform: translateX(0%);
  }
}
.chat-logo-glow:before {
  transform: scale(1.2);
  box-shadow: 0 0 15px #d35400;
  filter: blur(3px);
}

.chat-logo-glow {
  color: #ffa502;
  box-shadow: 0 0 15px #d35400;
  text-shadow: 0 0 15px #d35400;
}
.badge {
  position: relative;
  top: -24px;
  right: 15px;
  padding: 0px 0px;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #ed3535;
  color: white;
  animation-delay: 2s;
  animation: appearGrow 0.75s;
}
@keyframes appearGrow {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.bg-token-select {
  background-color: #071325 !important;
}