$themes: (
  light: (
    fontFamily: 'Gilroy',
    bgModalBackground: #ffffff,
    bgModalBackgroundSettings: #ffffff,
    bodyBackgroundColor: #e5e5e5,
    headerFooterBackground: #fff,
    textColor: #000,
    textColorSwap: #000,
    textColorAmount: #000,
    textColorFarm: #5a5a62,
    textColorBlue: #007aff,
    lightColor: #ffffff,
    toggleBackground: #dfe4ea,
    loadingOutline: #007aff,
    loginButtonBorder: none,
    loginButtonColor: #007aff,
    lightColorButton: #ffffff,
    spaceBg: #e3e3e3,
    buttonActive: #bdb8b8,
    toTokenField: #8d8d8d,
    toTokenFieldBody: #eeeef0,
    borderFarmSearchInput: 1px solid #8d8d8d,
    farmSearchInputBackground: #fff,
    farmGridView: white,
    farmLoading: rgba(238, 238, 240, 0.5),
    farmBtnHarvest: #d3e8fd,
    farmBtnHarvestText: rgba(0, 122, 255, 1),
    scrollbarTrack: rgba(229, 242, 255, 1),
    scrollbarThumb: inset 0 0 100px rgb(213 212 212),
    farmBtnPair: #007aff,
    textColorDark: #fff,
    hoverTab: rgb(211 211 219 / 90%),
    refreshIcon: #fff,
    pairConfig: #e5f2ff,
    antSwitch: #e3e3e3,
    RewardDetailsPanel: #e3e3e3,
    fgWidget: #0f0f0f,
    bgNotificationBackground: #f8f8fa,
    scrollUp: rgb(255, 255, 255),
    layoutFooter: #f1f1f1,
    panelBackground: #eeeef0,
    swapSettings: #ffffff,
    swapOptionBack: #eeeef0,
    houdiniSwitch: rgb(221 225 229),
    buttonConnectRight: rgb(210 210 210 / 99%),
    profileColor: #2d2d31,
    RightBackground: #fff,
    bgHoudiniSwap: #e3e3e3,
    auditPanel: #ffffff,
    auditLogoBackground: transparent,
    profileBackcolor: #eeeef0,
    hoverConnectButton: rgb(211 211 219 / 90%),
    overviewToken1: #080808,
    overviewToken2: #19191a,
    btnPremium: #36394c,
    bubbleUsrMsg: #007aff,
    chatBackgroundMobile: hsl(0deg 0% 100% / 98%),
    auditPanelBackgroundColor: white,
    auditLogoOverallBackgroundColor: white,
    auditCardBackgroundColor: #ededed,
    auditHeaderBackground: #dbdbdb,
    auditPanelBordergroundColor: black,
    chatBodyPromptBackground: rgb(90, 90, 90),
    chatBodyPromptColor: white,
    aiInfoWrapperChatBodyBackground: rgb(214, 214, 214),
    aiInfoWrapperChatBodycolor: black,
    notificatoinPopoverBgColor: white,
    notificatoinPopoverColor: black,
    btnWallet: black,
    crossChainRefreshBg: #dfdfdf,
    arcMenuLeftbarBgColor: #ffffff,
    arcMenuLeftbarTextColor: #000,
    arcMenuLeftbarWalletBgColor: #d2d2d2fc,
    crossChainFromTokenBg: #dfdfdf,
    swapModeSelectorColor: #7b7979,
    swapModeSelectorActiveColor: black,
    limitOrderTxColor: black,
    farmBgColor: #eeeef0,
    farmViewModeBorder: 0.5px solid #8d8d8d,
    farmViewModeBorderHover: #8d8d8d80,
    rampContainerBg: #eeeef0,
    intervalSelectorBg: #747679,
    promptTextColor: white,
    promptTextActiveColor: black,
    limitOrderActionBtBg: #d3bebe,
    transactionInfoBg: #dfdfdf,
  ),
  dark: (
    fontFamily: 'Gilroy',
    bgModalBackground: #0b1e3c,
    bgModalBackgroundSettings: #212121,
    bodyBackgroundColor: linear-gradient(180deg, #001833 0%, #000000 100%),
    RightBackground: #0b1e3c,
    headerFooterBackground: #36394c,
    textColor: #ffffff,
    textColorSwap: #cdcdcd,
    textColorAmount: #b9b9bf,
    textColorFarm: #b9b9bf,
    textColorDark: #000,
    toggleBackground: #606071,
    loadingOutline: #fff,
    loginButtonBorder: none,
    loginButtonColor: #ffffff,
    textColorBlue: #ffffff,
    lightColor: #0000,
    lightColorButton: #ffffff,
    spaceBg: #171718,
    buttonActive: #3e3b3b,
    toTokenField: #8d8d8d,
    toTokenFieldBody: #2d2d31,
    borderFarmSearchInput: none,
    farmSearchInputBackground: #0b1e3c,
    farmLoading: rgba(0, 0, 0, 0.5),
    // farmGridView:
    //   linear-gradient(156.25deg, #121212 72.03%, rgba(18, 18, 18, 0.4) 96.34%),
    farmGridView: #031531,
    farmBtnHarvest: #0b1e3c,
    farmBtnHarvestText: #000,
    scrollbarTrack: #2c2c2c,
    scrollbarThumb: inset 0 0 100px rgba(18, 18, 18),
    farmBtnPair: #007aff,
    hoverTab: rgba(17, 17, 20, 0.9),
    refreshIcon: #000,
    pairConfig: #003166,
    antSwitch: #5c5c5c,
    RewardDetailsPanel: #1e1e1e,
    fgWidget: #323232,
    bgNotificationBackground: #2c2c2c,
    scrollUp: linear-gradient(180deg, #000000, #0000),
    layoutFooter: #0f0f0f,
    panelBackground: #040f21,
    swapSettings: #0b1e3c,
    swapOptionBack: #2d2d31,
    houdiniSwitch: rgb(1, 12, 25),
    buttonConnectRight: #243550,
    profileColor: #ffffff,
    bgHoudiniSwap: #2d2d31,
    auditPanel: #071325,
    auditLogoBackground: transparent,
    profileBackcolor: transparent,
    hoverConnectButton: rgb(75 85 96 / 99%),
    overviewToken1: #fcfcfc,
    overviewToken2: #f8f8ff,
    btnPremium: transparent,
    bubbleUsrMsg: #274f8c,
    chatBackgroundMobile: transparent,
    auditPanelBackgroundColor: #071325,
    auditLogoOverallBackgroundColor: #212a29,
    auditCardBackgroundColor: #171717,
    auditHeaderBackground: #171718,
    auditPanelBordergroundColor: #071325,
    chatBodyPromptBackground: #041531,
    chatBodyPromptColor: white,
    aiInfoWrapperChatBodyBackground: #0b1e3c,
    aiInfoWrapperChatBodycolor: white,
    notificatoinPopoverBgColor: black,
    notificatoinPopoverColor: white,
    btnWallet: #ffffffba,
    crossChainRefreshBg: #031531,
    arcMenuLeftbarBgColor: #040919,
    arcMenuLeftbarTextColor: #afc0d4,
    arcMenuLeftbarWalletBgColor: #0b1e3c,
    crossChainFromTokenBg: #0b1e3c,
    swapModeSelectorColor: #afc0d4,
    swapModeSelectorActiveColor: white,
    limitOrderTxColor: #afc0d4,
    farmBgColor: #081325,
    farmViewModeBorder: 0.5px solid #ffffff33,
    farmViewModeBorderHover: #ffffff80,
    rampContainerBg: #081325,
    intervalSelectorBg: #031531,
    promptTextColor: #6d788a,
    promptTextActiveColor: white,
    limitOrderActionBtBg: #391f2d,
    transactionInfoBg: #051632,
  ),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
